import type { PagedAndSortedResultRequestDto, PagedResultRequestDto } from '@abp/ng.core';

export const EMPTY_UUID = '00000000-0000-0000-0000-000000000000';

export interface LookupDto<TKey> {
  id: TKey;
  displayName?: string;
}

export interface LookupRequestDto extends PagedAndSortedResultRequestDto {
  filter?: string;
}

export interface CustomerLookupRequestDto extends LookupRequestDto {
  ids?: string[];
}

export interface AddressDto {
  city?: string;
  street1?: string;
  street2?: string;
  postCode?: string;
  region?: string;
  country?: string;
}

export interface GeoPointDto {
  latitude?: number;
  longitude?: number;
}

export interface CountDto<TKey> extends LookupDto<TKey> {
  count: number;
}
