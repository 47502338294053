import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { LocationTreeNode } from '@/locations/models/location-tree-node.model';
import { LocationDetailsDto, LocationService } from '@proxy/register-service/locations';
import { Subject, debounceTime, distinctUntilChanged, of } from 'rxjs';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
})
export class LocationPickerComponent implements OnInit {
  inputChanged: Subject<string> = new Subject<string>();
  @Input() name: string;
  @Input() readonly: boolean = false;
  private _customerId: string;
  public get customerId(): string {
    return this._customerId;
  }
  @Input()
  public set customerId(value: string) {
    this._customerId = value;
    if (!this._customerId)
      this.onRemove();
  }

  @Output()
  nodeSelected = new EventEmitter<LocationDetailsDto>();

  term = null;
  selectedLocation?: LocationDetailsDto = null;
  selectedLocationTemp?: LocationDetailsDto = null;
  isModalOpen = false;

  data: PagedResultDto<any> = {
    items: [],
    totalCount: 0,
  };

  constructor(public readonly list: ListService, public readonly service: LocationService) { }

  ngOnInit(): void {
    this.inputChanged.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(_ => this.list.filter = this.term);

    const getData = (query: ABP.PageQueryParams) =>
      query?.filter ?
        this.service.getLookup({
          ...query,
          customerId: this.customerId,
          maxResultCount: 20
        } as any) : of({ totalCount: 0, items: [] });

    const setData = list => (this.data = list);

    this.list.hookToQuery(getData).subscribe(setData);
  }

  onSelectedNodeChange($event: LocationTreeNode) {
    this.selectedLocationTemp = $event;
  }

  onSelectClicked() {
    this.name = null;
    this.isModalOpen = true;
  }

  onSelect() {
    this.selectedLocation = this.selectedLocationTemp;
    this.selectedLocationTemp = null;
    this.name = this.selectedLocation.fullName;
    this.isModalOpen = false;
    this.nodeSelected.emit(this.selectedLocation);
  }

  onCancel() {
    this.name = null;
    this.selectedLocationTemp = null;
    this.term = null;
    this.list.filter = null;
    this.isModalOpen = false;
  }

  onRemove() {
    this.selectedLocation = null;
    this.selectedLocationTemp = null;
    this.name = null;
    this.nodeSelected.emit(this.selectedLocation);
  }

  onPick(row) {
    this.selectedLocationTemp = row;
    this.onSelect();
    this.term = null;
  }

  onInputChange(evnt: any) {
    this.term = evnt.target.value;
    this.inputChanged.next(this.term);
  }
}
