import { eSaasComponents, SaasCreateFormPropContributors } from '@volo/abp.ng.saas';
import { SaasTenantDto } from '@volo/abp.ng.saas/proxy';
import { ePropType, FormProp, FormPropList } from '@abp/ng.theme.shared/extensions';
import { Validators } from '@angular/forms';

const confirmAdminPasswordProp = new FormProp<SaasTenantDto>({
  type: ePropType.Password,
  name: 'ConfirmAdminPassword',
  displayName: 'SaasService::ConfirmAdminPassword',
  // validators: () => [Validators.required],
});

export function confirmAdminPasswordPropContributor(propList: FormPropList<SaasTenantDto>) {
  propList.addByIndex(confirmAdminPasswordProp, 4);
}

export const saasCreateFormPropContributors: SaasCreateFormPropContributors = {
  // enum indicates the page to add contributors to
  [eSaasComponents.Tenants]: [
    confirmAdminPasswordPropContributor,
    // You can add more contributors here
  ],
};

// export const identityEditFormPropContributors = identityCreateFormPropContributors;
// you may define different contributors for edit form if you like
