import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from '@proxy/settings/app-settings.service';
import { AppSettingsDto } from '@proxy/settings/models';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-register-settings',
  templateUrl: './register-settings.component.html',
  styleUrls: ['./register-settings.component.scss']
})
export class RegisterSettingsComponent implements OnInit {
  form!: FormGroup;

  saving = false;

  constructor(
    private appSettingsService: AppSettingsService,
    private fb: FormBuilder,
    private toasterService: ToasterService,
  ) {}

  ngOnInit() {
    this.getData();
  }

  private getData() {
    this.appSettingsService.get().subscribe(res => {
      this.buildForm(res);
    });
  }

  private buildForm(appSettings: AppSettingsDto) {
    this.form = this.fb.group({
      assetCodePattern: [appSettings.assetCodePattern, []],
      reportNumberPattern: [appSettings.reportNumberPattern, []],
      inspectionAutoApproval: [appSettings.inspectionAutoApproval, []],
    });
  }

  submit() {
    if (this.saving || this.form.invalid) return;

    this.saving = true;
    this.appSettingsService
      .update(this.form.value)
      .pipe(finalize(() => (this.saving = false)))
      .subscribe(() => {
        this.toasterService.success('AdministrationService::SuccessfullySaved');
        this.getData();
      });
  }
}