import { Injectable } from '@angular/core';
import { EnvironmentService, RestService, Rest } from '@abp/ng.core';
import { GetInspectionsInput } from '@proxy/servicing-service/inspections';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs';
import { receiveDownload } from '@proxy/shared/receive-download';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  apiName = 'CertReportService';
  urlPrefix = '/api/cert-report/dashboard';

  getApiDocsUrl = () => this.environment.getApiUrl(this.apiName);

  exportUpcomingInspectionsCountPerCustomer = (input: GetInspectionsInput, format = 'pdf') =>
    this.restService.request<any, HttpResponse<Blob>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/upcoming-inspections-count-per-customer`,
        responseType: 'blob',
        params: {
          approvedOnMax: input.approvedOnMax,
          approvedOnMin: input.approvedOnMin,
          approverId: input.approverId,
          assetCode: input.assetCode,
          customerId: input.customerId,
          assetId: input.assetId,
          assetPrimaryID: input.assetPrimaryID,
          assetSecondaryID: input.assetSecondaryID,
          assetPrimaryOrSecondaryID: input.assetPrimaryOrSecondaryID,
          categoryId: input.categoryId,
          categoryName: input.categoryName,
          categoryNickname: input.categoryNickname,
          includeSubCategories: input.includeSubCategories,
          doneOnMax: input.doneOnMax,
          doneOnMin: input.doneOnMin,
          dueOnMax: input.dueOnMax,
          dueOnMin: input.dueOnMin,
          inspectorId: input.inspectorId,
          isActive: input.isActive,
          isHistorical: input.isHistorical,
          jobNumber: input.jobNumber,
          locationId: input.locationId,
          includeSubLocations: input.includeSubLocations,
          maxResultCount: input.maxResultCount,
          notes: input.notes,
          poNumber: input.poNumber,
          reportNumber: input.reportNumber,
          result: input.result,
          siteId: input.siteId,
          skipCount: input.skipCount,
          sorting: input.sorting,
          statusId: input.statusId,
          term: input.term,
          typeId: input.typeId,
          format: format
        },
      },
      { apiName: this.apiName, observe: Rest.Observe.Response }).pipe(map(resp => receiveDownload(resp)));

  exportOverdueInspectionsCountPerCustomer = (input: GetInspectionsInput, format = 'pdf') =>
    this.restService.request<any, HttpResponse<Blob>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/overdue-inspections-count-per-customer`,
        responseType: 'blob',
        params: {
          approvedOnMax: input.approvedOnMax,
          approvedOnMin: input.approvedOnMin,
          approverId: input.approverId,
          assetCode: input.assetCode,
          customerId: input.customerId,
          assetId: input.assetId,
          assetPrimaryID: input.assetPrimaryID,
          assetSecondaryID: input.assetSecondaryID,
          assetPrimaryOrSecondaryID: input.assetPrimaryOrSecondaryID,
          categoryId: input.categoryId,
          categoryName: input.categoryName,
          categoryNickname: input.categoryNickname,
          includeSubCategories: input.includeSubCategories,
          doneOnMax: input.doneOnMax,
          doneOnMin: input.doneOnMin,
          dueOnMax: input.dueOnMax,
          dueOnMin: input.dueOnMin,
          inspectorId: input.inspectorId,
          isActive: input.isActive,
          isHistorical: input.isHistorical,
          jobNumber: input.jobNumber,
          locationId: input.locationId,
          includeSubLocations: input.includeSubLocations,
          maxResultCount: input.maxResultCount,
          notes: input.notes,
          poNumber: input.poNumber,
          reportNumber: input.reportNumber,
          result: input.result,
          siteId: input.siteId,
          skipCount: input.skipCount,
          sorting: input.sorting,
          statusId: input.statusId,
          term: input.term,
          typeId: input.typeId,
          format: format
        },
      },
      { apiName: this.apiName, observe: Rest.Observe.Response }).pipe(map(resp => receiveDownload(resp)));

  constructor(private restService: RestService, private environment: EnvironmentService) {}
}
