import { TreeNode } from "@abp/ng.components/tree";
import { LocationDetailsDto } from "@proxy/register-service/locations";

export class LocationTreeNode extends TreeNode<any> {
  // fullName?: string;
  // notes?: string;
  // pictureUrl?: string;
  // path?: string;
  // attributeDefinitionGroupId?: string;
  // isActive?: boolean;
}

export function mapLocationsTreeNode(dtos: LocationDetailsDto[]): LocationTreeNode[] {
  if (!dtos) return [];

  return dtos.map(mapLocationTreeNode);
};

export function mapLocationTreeNode(dto: LocationDetailsDto): LocationTreeNode {
  const node = new LocationTreeNode(dto);
  // node.id = dto.id;
  // node.name = dto.name;
  // node.fullName = dto.fullName;
  // node.path = dto.path;
  // node.parentId = dto.parentId;
  // node.isActive = dto.isActive;
  return node;
};
