<div class="card h-100">
  <div class="card-body" [abpLoading]="isLoading">
    <abp-tree
      #treeRef
      [nodes]="nodes"
      [(expandedKeys)]="expandedKeys"
      [selectedNode]="selectedNode"
      [draggable]="false"
      (selectedNodeChange)="onSelectedNode($event)"
      (nzExpandChange)="onExpandChange($event)"
    >
      <ng-template abpTreeNodeTemplate let-node>
        <div
          class="node-container d-flex flex-row"
          [class.node-selected]="node.origin?.id === selectedNode?.id"
          (mouseover)="node.isHovered = true"
          (mouseleave)="node.isHovered = false"
        >
          <i class="fas fa-folder fs-15px text-primary me-1"></i>
          {{ node.title }}
          <div *ngIf="node.isHovered && !readonly" class="ms-auto">
            <i class="fas fa-plus fa-lg ms-3" (click)="onAddSubNode(node, $event)"></i>
            <i class="fas fa-trash-alt fa-lg ms-2" (click)="onDelete(node, $event)"></i>
          </div>
        </div>
      </ng-template>
    </abp-tree>
    <p class="text-muted" *ngIf="!nodes || !nodes.length">
      {{ 'RegisterService::NoLocations' | abpLocalization }}
    </p>
  </div>
</div>
