import { Injectable } from '@angular/core';
import { Rest, EnvironmentService, RestService } from '@abp/ng.core';
import { GetAssetsInput } from '@proxy/register-service/assets';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs';
import { receiveDownload } from '@proxy/shared/receive-download';
import { GetInspectionsInput, InspectionState } from '@proxy/servicing-service/inspections';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  apiName = 'CertReportService';
  urlPrefix = '/api/cert-report/reports';

  getDesignerModelAction = (): string => `${this.urlPrefix}/designer/model`;

  getServiceHostUrl = () => this.environment.getApiUrl(this.apiName);

  exportAssetsList = (input: GetAssetsInput, format = 'pdf') =>
    this.restService.request<any, HttpResponse<Blob>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/assets-list`,
        responseType: 'blob',
        params: {
          ...input,
          maxResultCount: input.maxResultCount || 1000,
          format: format
        },
      },
      { apiName: this.apiName, observe: Rest.Observe.Response }).pipe(map(resp => receiveDownload(resp)));

  exportAssetsListFromIds = (ids: string[], format = 'pdf') =>
    this.restService.request<any, HttpResponse<Blob>>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/assets-list-from-ids`,
        responseType: 'blob',
        params: {
          format: format
        },
        body: ids
      },
      { apiName: this.apiName, observe: Rest.Observe.Response }).pipe(map(resp => receiveDownload(resp)));

  exportInspectionsList = (input: GetInspectionsInput, listType: string, format = 'pdf') => {
    let states = input.state !== null && input.state !== undefined ? [input.state] : [];
    switch (listType) {
      case 'pending':
        states.push(InspectionState.DRAFT, InspectionState.CANCELLED);
        break;
      case 'completed':
        states.push(InspectionState.APPROVED);
        break;
      case 'upcoming':
        states.push(InspectionState.APPROVED);
        break;
      case 'past':
        states.push(InspectionState.APPROVED, InspectionState.ARCHIVED);
        break;
      default:
        states = null;
        break;
    }

    return this.restService.request<any, HttpResponse<Blob>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/inspections-list`,
        responseType: 'blob',
        params: {
          ...input,
          states: states,
          maxResultCount: input.maxResultCount || 1000,
          format: format
        },
      },
      { apiName: this.apiName, observe: Rest.Observe.Response }).pipe(map(resp => receiveDownload(resp)));
  }

  exportInspectionsListFromIds = (ids: string[], listType: string, format = 'pdf') => {
    return this.restService.request<any, HttpResponse<Blob>>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/inspections-list-from-ids`,
        responseType: 'blob',
        params: {
          format: format
        },
        body: ids
      },
      { apiName: this.apiName, observe: Rest.Observe.Response }).pipe(map(resp => receiveDownload(resp)));
  }

  exportUpcomingInspectionsList = (input: GetInspectionsInput, format = 'pdf') =>
    this.restService.request<any, HttpResponse<Blob>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/upcoming-inspections-list`,
        responseType: 'blob',
        params: {
          ...input,
          maxResultCount: input.maxResultCount || 1000,
          format: format
        },
      },
      { apiName: this.apiName, observe: Rest.Observe.Response }).pipe(map(resp => receiveDownload(resp)));

  exportOverdueInspectionsList = (input: GetInspectionsInput, format = 'pdf') =>
    this.restService.request<any, HttpResponse<Blob>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/overdue-inspections-list`,
        responseType: 'blob',
        params: {
          ...input,
          maxResultCount: input.maxResultCount || 1000,
          format: format
        },
      },
      { apiName: this.apiName, observe: Rest.Observe.Response }).pipe(map(resp => receiveDownload(resp)));

  constructor(private restService: RestService, private environment: EnvironmentService) { }
}
