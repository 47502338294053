import { RestService } from "@abp/ng.core";
import { Pipe, PipeTransform } from "@angular/core";
import { lastValueFrom } from 'rxjs';

@Pipe({
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private restService: RestService
  ) { }

  async transform(src: string, fallback: string): Promise<string> {
    try {
      // if src is an absolute url
      if (src?.startsWith('http')) {
        return src;
      }

      const imageBlob = await lastValueFrom(this.restService.request<any, any>({
        method: 'GET',
        url: src,
        responseType: 'blob'
      },
        {}));

      if (imageBlob?.size <= 0) {
        return fallback;
      }

      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });

    } catch (error) {
      console.error(error);
      return fallback;
    }
  }
}

