import { EnvironmentService, RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CategoryPictureUpdateDto } from './models';
import type {
  CategoryCreateDto,
  CategoryDto,
  CategoryUpdateDto,
  CategoryDetailsDto,
  GetCategoriesInput,
} from './models';
import type { AttachmentCreateDto, AttachmentDto, AttachmentUpdateDto, GetAttachmentsInput } from '@proxy/attachments/models';
import type { CategoryLookupRequestDto } from '../shared/models';
import { EMPTY_UUID, type LookupDto, type LookupRequestDto } from '@proxy/shared/models';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  apiName = 'RegisterService';

  create = (input: CategoryCreateDto) => {
    var formdata = new FormData();
    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        const val = input[key];
        formdata.append(key, val != null ? val : '');
      }
    }

    return this.restService.request<any, CategoryDto>(
      {
        method: 'POST',
        url: '/api/register/categories',
        body: formdata,
      },
      { apiName: this.apiName }
    );
  }

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/register/categories/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, CategoryDto>(
      {
        method: 'GET',
        url: `/api/register/categories/${id}`,
      },
      { apiName: this.apiName }
    );

  getPicture = (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/register/categories/${id}/picture`,
        responseType: 'blob'
      },
      { apiName: this.apiName }
    );

  getPictureUrl = (id: string) =>
    `/api/register/categories/${id}/picture`;

  deletePicture = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/register/categories/${id}/picture`,
      },
      { apiName: this.apiName }
    );

  getLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/register/categories/lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount
        },
      },
      { apiName: this.apiName }
    );

  getList = (input: GetCategoriesInput) =>
    this.restService.request<any, PagedResultDto<CategoryDetailsDto>>(
      {
        method: 'GET',
        url: '/api/register/categories',
        params: {
          term: input.term,
          name: input.name,
          isActive: input.isActive,
          notes: input.notes,
          picture: input.picture,
          parentId: input.parentId,
          attributeDefinitionGroupId: input.attributeDefinitionGroupId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        }
      },
      { apiName: this.apiName }
    );

  getChildren = (parentId?: string) =>
    this.restService.request<any, PagedResultDto<CategoryDetailsDto>>(
      {
        method: 'GET',
        url: `/api/register/categories`,
        params: {
          parentId: parentId ?? EMPTY_UUID,
          maxResultCount: 1000
        }
      },
      { apiName: this.apiName }
    );

  getDetails = (id: string) =>
    this.restService.request<any, CategoryDetailsDto>(
      {
        method: 'GET',
        url: `/api/register/categories/${id}/details`,
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CategoryUpdateDto) =>
    this.restService.request<any, CategoryDto>(
      {
        method: 'PUT',
        url: `/api/register/categories/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  addManufacturer = (id: string, manufacturerId: string) =>
    this.addManufacturers(id, [manufacturerId]);

  addManufacturers = (id: string, manufacturerIds: string[]) =>
    this.restService.request<any, CategoryDto>(
      {
        method: 'POST',
        url: `/api/register/categories/${id}/manufacturers`,
        body: manufacturerIds,
      },
      { apiName: this.apiName }
    );

  removeManufacturer = (id: string, manufacturerId: any) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/register/categories/${id}/manufacturers/${manufacturerId}`,
      },
      { apiName: this.apiName }
    );

  deactivate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/register/categories/${id}/deactivate`,
      },
      { apiName: this.apiName }
    );

  activate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `/api/register/categories/${id}/activate`,
      },
      { apiName: this.apiName }
    );

  // addManufacturer = (id: string, manufacturerId: string, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, IActionResult>({
  //     method: 'POST',
  //     url: `/api/register/categories/${id}/manufacturers/${manufacturerId}`,
  //   },
  //   { apiName: this.apiName,...config });

  // attach = (id: string, input: AttachmentCreateDto<string>, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, AttachmentDto<string>>({
  //     method: 'POST',
  //     url: `/api/register/categories/${id}/attachments`,
  //     body: input.file,
  //   },
  //   { apiName: this.apiName,...config });

  // create = (input: CategoryCreateDto, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, CategoryDto>({
  //     method: 'POST',
  //     url: '/api/register/categories',
  //     body: input,
  //   },
  //   { apiName: this.apiName,...config });

  createWithPicture = (input: CategoryCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CategoryDto>({
      method: 'POST',
      url: '/api/register/categories',
      body: input.picture,
    },
    { apiName: this.apiName,...config });

  // delete = (id: string, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, void>({
  //     method: 'DELETE',
  //     url: `/api/register/categories/${id}`,
  //   },
  //   { apiName: this.apiName,...config });

  // downloadAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, FileStreamResult>({
  //     method: 'GET',
  //     url: `/api/register/categories/${id}/attachments/${attachmentId}/download`,
  //   },
  //   { apiName: this.apiName,...config });

  // get = (id: string, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, CategoryDto>({
  //     method: 'GET',
  //     url: `/api/register/categories/${id}`,
  //   },
  //   { apiName: this.apiName,...config });

  // getAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, AttachmentDto<string>>({
  //     method: 'GET',
  //     url: `/api/register/categories/${id}/attachments/${attachmentId}`,
  //   },
  //   { apiName: this.apiName,...config });

  // getAttachments = (id: string, input: GetAttachmentsInput<string>, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, PagedResultDto<AttachmentDto<string>>>({
  //     method: 'GET',
  //     url: `/api/register/categories/${id}/attachments`,
  //     params: { term: input.term, title: input.title, contentType: input.contentType, fileName: input.fileName, fileUrl: input.fileUrl, folder: input.folder, ownerType: input.ownerType, ownerId: input.ownerId, isActive: input.isActive, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
  //   },
  //   { apiName: this.apiName,...config });

  // getDetails = (id: string, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, CategoryDetailsDto>({
  //     method: 'GET',
  //     url: `/api/register/categories/${id}/details`,
  //   },
  //   { apiName: this.apiName,...config });

  // getList = (input: GetCategoriesInput, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, PagedResultDto<CategoryDto>>({
  //     method: 'GET',
  //     url: '/api/register/categories',
  //     params: { term: input.term, name: input.name, isActive: input.isActive, notes: input.notes, parentId: input.parentId, attributeDefinitionGroupId: input.attributeDefinitionGroupId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
  //   },
  //   { apiName: this.apiName,...config });

  // getLookup = (input: CategoryLookupRequestDto, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, PagedResultDto<LookupDto<string>>>({
  //     method: 'GET',
  //     url: '/api/register/categories/lookup',
  //     params: { parentId: input.parentId, filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
  //   },
  //   { apiName: this.apiName,...config });

  // getPicture = (id: string, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, FileStreamResult>({
  //     method: 'GET',
  //     url: `/api/register/categories/${id}/picture`,
  //   },
  //   { apiName: this.apiName,...config });

  removeAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/register/categories/${id}/attachments/${attachmentId}`,
    },
    { apiName: this.apiName,...config });

  removeCategoryPicture = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>({
      method: 'DELETE',
      url: `/api/register/categories/${id}/picture`,
    },
    { apiName: this.apiName,...config });

  // removeManufacturer = (id: string, manufacturerId: string, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, IActionResult>({
  //     method: 'DELETE',
  //     url: `/api/register/categories/${id}/manufacturers/${manufacturerId}`,
  //   },
  //   { apiName: this.apiName,...config });

  // update = (id: string, input: CategoryUpdateDto, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, CategoryDto>({
  //     method: 'PUT',
  //     url: `/api/register/categories/${id}`,
  //     body: input,
  //   },
  //   { apiName: this.apiName,...config });

  // updateAttachment = (id: string, attachmentId: string, input: AttachmentUpdateDto<string>, config?: Partial<Rest.Config>) =>
  //   this.restService.request<any, AttachmentDto<string>>({
  //     method: 'PUT',
  //     url: `/api/register/categories/${id}/attachments/${attachmentId}`,
  //     body: input,
  //   },
  //   { apiName: this.apiName,...config });

  updateCategoryPicture = (id: string, model: CategoryPictureUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>({
      method: 'PUT',
      url: `/api/register/categories/${id}/picture`,
      body: model.file,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService, private environment: EnvironmentService) {}
}
