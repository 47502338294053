import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true
  },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application
      },
      {
        path: '/home',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application
      },
      {
        path: '/dashboard',
        name: '::Menu:Dashboard',
        iconClass: 'fas fa-chart-line',
        order: 2,
        layout: eLayoutType.application,
        requiredPolicy: 'Sitecert.Dashboard.Host  || Sitecert.Dashboard.Tenant'
      },
      {
        path: '/inspections',
        name: 'ServicingService::Menu:ServicingService',
        iconClass: 'fas fa-toolbox',
        order: 10,
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.Inspections'
      },
      {
        path: '/assets',
        name: 'RegisterService::Menu:RegisterService',
        iconClass: 'fas fa-book',
        order: 20,
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.Assets'
      },
      {
        path: '/accounts',
        name: '::Menu:Accounts',
        iconClass: 'fas fa-users',
        order: 50,
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.Customers.Edit || ServicingService.Staffs.Edit'
      },
      {
        path: '/settings',
        name: '::Menu:Settings',
        iconClass: 'fas fa-cogs',
        order: 90,
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.AttributeDefinitions.Edit || RegisterService.Manufacturers.Edit || ServicingService.InspectionStatuses.Edit || Sitecert.Tenancy.Edit || CertReportService.ReportTemplates.Design'
      },
      {
        path: '/inspections',
        name: '::Menu:Inspections',
        iconClass: 'fas fa-toolbox',
        order: 110,
        layout: eLayoutType.application,
        invisible: true,
        requiredPolicy: 'ServicingService.Inspections'
      },
    ]);

    routes.remove(["AbpIdentity::OrganizationUnits"]);
  };
}
