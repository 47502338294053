import { mapEnumToOptions } from '@abp/ng.core';

export enum InspectionState
{
	DRAFT = 'DRAFT',
	APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  ARCHIVED = 'ARCHIVED'
}

export const inspectionStateOptions = mapEnumToOptions(InspectionState);
