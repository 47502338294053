import { HttpResponse } from "@angular/common/http";

export function receiveDownload(resp: HttpResponse<Blob>): any {
    const disposition = resp.headers.get('content-disposition') || '';
    const fileName = decodeURI(disposition.match(/filename=([^;\n]*)/)[1]);
    const contentType = resp.body.type
    const data = resp.body;

    return { fileName, contentType, data };
}
