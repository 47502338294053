import { ApplicationInfo, EnvironmentService } from '@abp/ng.core';
import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
import { TenancyService } from '@proxy/administration-service/tenancy';

@Component({
  selector: 'app-logo',
 template: `
    <a href="/">
      <!-- <a href="/"><div class="lpx-brand-logo"></div></a> -->
      <div *ngIf="imageSrc; else appName" [style.backgroundImage]="'url(' + imageSrc + ')'" class="lpx-brand-logo"></div>
    </a>
    <ng-template #appName>
      <span class="fs-4">{{ appInfo.name }}</span>
    </ng-template>
  `,
  styles: [
  ]
})
export class LogoComponent implements AfterViewInit {

  imageSrc: any;

  constructor(
    private elRef: ElementRef,
    private environment: EnvironmentService,
    private tenancyService: TenancyService,
    // private sanitizer: DomSanitizer
  ){}

  ngAfterViewInit(): void {
    var logoUrl = "/assets/images/logo/logo-dark.png";
    var logoReverseUrl = "/assets/images/logo/logo-light.png";
    // this.elRef.nativeElement.style.setProperty('--logo', `url(${logoUrl})`);
    // this.elRef.nativeElement.style.setProperty('--logo-reverse', `url(${logoReverseUrl})`);
    // this.elRef.nativeElement.style.setProperty('--logo', `url('')`);
    // this.elRef.nativeElement.style.setProperty('--logo-reverse', `url('')`);
    this.tenancyService.getLogo().subscribe(data => {
      // let objectUrl = URL.createObjectURL(data);
      // this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
      this.loadPictureFromBlob(data);
    });
  }

  private loadPictureFromBlob(blob) {
    if (blob) {
      const reader = new FileReader(); // HTML5 FileReader API
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
      reader.readAsDataURL(blob);
    }
  }

  get appInfo(): ApplicationInfo {
    return this.environment.getEnvironment().application;
  }

  // constructor(private route: ActivatedRoute, private elRef: ElementRef){}
  // ngAfterViewInit() {
  //     this.route.queryParams.subscribe(params => {
  //         const tenant = params['Tenant']
  //         const logoUrl = "YOUR LOGO URL";
  //         this.elRef.nativeElement.style.setProperty('--logo-reverse', `url(${logoUrl})`);
  //     })
  // }

  // this.actions.pipe(ofActionSuccessful(SetStyle)).subscribe(() => {
  //   document.documentElement.style.setProperty(
  //     '--logo',
  //     `url('logo url here')`,
  //   );

  //   document.documentElement.style.setProperty(
  //     '--logo-reverse',
  //     `url('reverse logo url here')`,
  //   );
  // });
}
