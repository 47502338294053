import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const ATTRIBUTE_DEFINITIONS_ATTRIBUTE_DEFINITION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/attribute-definition-groups',
        iconClass: 'fas fa-file-alt',
        name: 'RegisterService::Menu:AttributeDefinitions',
        parentName: '::Menu:Settings',
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.AttributeDefinitions && RegisterService.AttributeDefinitions.Edit'
      },
    ]);
  };
}
