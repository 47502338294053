import { TreeNode } from "@abp/ng.components/tree";
import { CategoryDetailsDto } from "@proxy/register-service/categories";

export class CategoryTreeNode extends TreeNode<any> {
  // fullName?: string;
  // notes?: string;
  // pictureUrl?: string;
  // path?: string;
  // attributeDefinitionGroupId?: string;
  // isActive?: boolean;
}

export function mapCategoriesTreeNode(dtos: CategoryDetailsDto[]): CategoryTreeNode[] {
  if (!dtos) return [];

  return dtos.map(mapCategoryTreeNode);
};

export function mapCategoryTreeNode(dto: CategoryDetailsDto): CategoryTreeNode {
  const node = new CategoryTreeNode(dto);
  // node.displayName = dto.displayName;
  // node.name = dto.name;
  // node.fullName = dto.fullName;
  // node.notes = dto.notes;
  // node.pictureUrl = dto.pictureUrl;
  // node.path = dto.path;
  // node.parentId = dto.parentId;
  // node.attributeDefinitionGroupId = dto.attributeDefinitionGroupId;
  // node.isActive = dto.isActive;
  return node;
};
