import { Observable, debounceTime, fromEvent, map, tap } from 'rxjs';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { LogoComponent } from '@/components/layout/logo/logo.component';
import { eThemeLeptonXComponents } from '@volosoft/abp.ng.theme.lepton-x';
import {
  AuthService,
  ConfigStateService,
  CurrentUserDto,
  ReplaceableComponentsService,
  RoutesService,
} from '@abp/ng.core';
import { UserComponent } from './users/user/user.component';
import { eIdentityComponents } from '@volo/abp.ng.identity';
import { PageComponent } from '@abp/ng.components/page';
import { ModalComponent, NavItemsService, UserMenuService } from '@abp/ng.theme.shared';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { RegisterSettingsComponent } from './shared/settings/register-settings/register-settings.component';
import { AssetSearchInputComponent } from './assets/search-input/asset-search-input.component';
import { QueueBottomComponent } from './queue/queue-bottom/queue-bottom.component';
import { ApiDocsComponent } from './admin/api/api-docs.component';
import { ToolbarService } from '@volosoft/ngx-lepton-x/layouts';
import { QueueService } from '@proxy/queue-service';
import { Store } from '@ngrx/store';
import { State } from '@/store/models/state.model';
import { UpdateQueueSizeAction } from '@/store/actions/queue.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarService } from '@volo/ngx-lepton-x.core';
import { AppMobileNavbarComponent } from '@/components/layout/navbar/mobile/mobile-navbar.component';
import { isMobile } from './shared/utils';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
    <abp-gdpr-cookie-consent></abp-gdpr-cookie-consent>
  `,
  entryComponents: [PageComponent, ModalComponent],
})
export class AppComponent implements OnInit {
  // currentUser$: Observable<CurrentUserDto> = this.configState.getOne$('currentUser');
  returnUrl: string;
  // public isLoaded = true;

  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    private settingTabs: SettingTabsService,
    private navItems: NavItemsService,
    private configState: ConfigStateService,
    private auth: AuthService,
    private toolbar: ToolbarService,
    private queue: QueueService,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private navbarService: NavbarService,
    private routesService: RoutesService,
    private userMenuService: UserMenuService
  ) {
    this.replaceComponents();
  }

  ngOnInit(): void {
    const resize$ = fromEvent(window, 'resize');
    resize$
      .pipe(
        map((i: any) => i),
        debounceTime(500) // He waits > 0.5s between 2 events emitted before running the next.
      )
      .subscribe((event) => {
        console.log('resize is finished');
        this.routesService.patch('queue', { invisible: isMobile() })
      });

    var user = this.configState.getOne('currentUser');
    if (user.isAuthenticated) {
      this.initMenus(user);
      this.queue.size().subscribe(data => this.store.dispatch(new UpdateQueueSizeAction(data.size)));
    }
  }

  private replaceComponents() {
    this.replaceableComponents.add({
      component: LogoComponent,
      key: eThemeLeptonXComponents.Logo,
    });
    this.replaceableComponents.add({
      component: UserComponent,
      key: eIdentityComponents.Users,
    });
    this.settingTabs.add([
      {
        name: 'Application',
        component: RegisterSettingsComponent,
      },
    ]);

    this.replaceableComponents.add({
      component: AppMobileNavbarComponent,
      key: eThemeLeptonXComponents.NavbarMobile,
    });
  }

  private initMenus(user: CurrentUserDto) {
    [
      {
        id: 'AssetSearchInput',
        order: 1,
        icon: 'bi bi-search',
        action: () => {
          this.router.navigate(['/assets']);
        }
      },
      {
        id: 'Queue',
        order: 2,
        icon: 'bi bi-list-ol',
        badge: this.store.select(store => store.queue.size),
        action: () => {
          this.router.navigate(['/queue']);
        }
      }
    ].forEach(item => this.toolbar.addItem(item));

    if (user.roles.includes('administrator')) {
      this.toolbar.addItem({
        id: 'ApiDocs',
        order: 3,
        icon: 'bi bi-journal-code',
        component: ApiDocsComponent
      });
    }

    if (user.roles.includes('customer')) {
      this.userMenuService.removeItem('LinkedAccounts');
      this.userMenuService.removeItem('AuthorityDelegation');
      this.userMenuService.removeItem('SecurityLogs');
      this.userMenuService.removeItem('Gdpr.GdprNavigation');
    }
  }

  get hasLoggedIn(): boolean {
    return this.auth.isAuthenticated;
  }

  login() {
    this.auth.navigateToLogin();
  }
}
