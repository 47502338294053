import { Injectable } from '@angular/core';
import { EnvironmentService, RestService } from '@abp/ng.core';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  apiName = 'AdministrationService';
  urlPrefix =  '/swagger';
  
  getApiDocsUrl = () => this.environment.getApiUrl(this.apiName);

  constructor(private restService: RestService, private environment: EnvironmentService) {}
}
