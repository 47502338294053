import type { AuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { AttachmentDto } from '@proxy/attachments';

export interface CategoryCreateDto {
  name?: string;
  notes?: string;
  picture?: File;
  pictureUrl?: string;
  parentId?: string;
  attributeDefinitionGroupId?: string;
  manufacturers?: string[];
  nickname?: string;
}

export interface CategoryDto extends AuditedEntityDto<string> {
  name?: string;
  fullName?: string;
  notes?: string;
  pictureUrl?: string;
  path?: string;
  parentId?: string;
  attributeDefinitionGroupId?: string;
  isActive?: boolean;
  nickname?: string;
}

export interface CategoryUpdateDto {
  id: string;
  name?: string;
  notes?: string;
  pictureUrl?: string;
  nickname?: string;
}

export interface CategoryDetailsDto extends CategoryDto {
  displayName?: string;
  parentName?: string;
  manufacturers: CategoryManufacturerDto[];
  inheritedManufacturers: CategoryManufacturerDto[];
  inheritedAttachments: AttachmentDto[];
  attachments: AttachmentDto[];
}

export interface CategoryManufacturerDto {
  categoryId?: string;
  categoryName?: string;
  manufacturerId?: string;
  manufacturerName?: string;
}

export interface CategoryPictureUpdateDto {
  file: any;
}

export interface GetCategoriesInput extends PagedAndSortedResultRequestDto {
  term?: string;
  name?: string;
  notes?: string;
  picture?: string;
  parentId?: string;
  attributeDefinitionGroupId?: string;
  isActive?: boolean;
}

// export interface IRemoteStreamContent {
//   fileName?: string;
//   contentType?: string;
//   contentLength?: number;
// }
