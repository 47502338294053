import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';
import { ApiDocsComponent } from './api/api-docs.component';
import { AdminRoutingModule } from './admin-routing.module';

@NgModule({
  declarations: [ApiDocsComponent],
  imports: [
    AdminRoutingModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    PageModule
  ],
})
export class AdminModule {}
