import type { TenancyDto, TenancyUpdateDto } from './models';
import { EnvironmentService, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { serialize } from 'object-to-formdata';

@Injectable({
  providedIn: 'root',
})
export class TenancyService {
  apiName = 'AdministrationService';

  get = () =>
    this.restService.request<any, TenancyDto>({
      method: 'GET',
      url: `/api/admin/tenancy`,
    },
      { apiName: this.apiName });

  getLogo = () =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/admin/tenancy/logo`,
        responseType: 'blob'
      },
      { apiName: this.apiName }
    );

  getLogoUrl = (path: string) =>
    this.environment.getApiUrl(this.apiName) + '/api/admin/' + path;

  deleteLogo = () =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/admin/tenancy/logo`,
      },
      { apiName: this.apiName }
    );

  update = (input: TenancyUpdateDto) => {
    const formdata = serialize(input, { indices: true, dotsForObjectNotation: true });

    return this.restService.request<any, TenancyDto>(
      {
        method: 'PUT',
        url: '/api/admin/tenancy',
        body: formdata,
      },
      { apiName: this.apiName }
    );
  }

  constructor(private restService: RestService, private environment: EnvironmentService) { }
}
