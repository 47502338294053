import { TreeAdapter, TreeComponent } from '@abp/ng.components/tree';
import { PagedResultDto } from '@abp/ng.core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { LocationTreeNode, mapLocationsTreeNode } from '@/locations/models/location-tree-node.model';
import { LocationDto, LocationService } from '@proxy/register-service/locations';
import { finalize, of } from 'rxjs';

@Component({
  selector: 'app-locations-tree',
  templateUrl: './locations-tree.component.html',
  styleUrls: ['./locations-tree.component.scss'],
})
export class LocationsTreeComponent implements OnInit, OnChanges {
  @ViewChild(TreeComponent) treeRef: TreeComponent;
  @Input() siteId!: string;
  @Input() customerId!: string;
  @Input() selectedNode: LocationDto;
  @Input() readonly: boolean;
  @Input() ignoreSiteId: boolean;
  @Input() allowSearch: boolean;

  @Output() addRootNode = new EventEmitter<void>();
  @Output() addSubNode = new EventEmitter<LocationTreeNode>();
  @Output() deleteNode = new EventEmitter<LocationTreeNode>();
  @Output() toggleActiveNode = new EventEmitter<LocationTreeNode>();
  @Output() selectedNodeChange = new EventEmitter<LocationDto>();

  isLoading: boolean;
  expandedKeys: any[] = [];
  nodes: LocationTreeNode[];
  treeAdapter: TreeAdapter;

  constructor(public readonly service: LocationService) { }

  ngOnInit(): void {
    this.loadChildren()
      .subscribe(res => this.nodes = mapLocationsTreeNode(res.items)
        .map(node => ({ ...node, isLeaf: false } as any))
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["customerId"] || (changes["siteId"] && !this.ignoreSiteId)) {
      this.loadChildren()
        .subscribe(res => this.nodes = mapLocationsTreeNode(res.items)
          .map(node => ({ ...node, isLeaf: false } as any))
        );
    }
  }

  loadChildren(parentId?) {
    if (!this.customerId || (!this.siteId  && !this.ignoreSiteId)) {
      return of(new PagedResultDto({ totalCount: 0, items: [] }));
    }

    this.isLoading = true;

    return this.service
                .getChildren(this.siteId, this.customerId, parentId)
                .pipe(finalize(() => (this.isLoading = false)));
  }

  onAddRootNode() {
    this.addRootNode.emit();
  }

  onAddSubNode(node: NzTreeNode, $event: MouseEvent) {
    this.addSubNode.emit(node.origin.entity);
    $event.stopPropagation();
  }

  onDelete(node: NzTreeNode, $event: MouseEvent) {
    this.deleteNode.emit(node.origin.entity);
    $event.stopPropagation();
  }

  onToggleActive(node: NzTreeNode, $event: MouseEvent) {
    this.toggleActiveNode.emit(node.origin.entity);
    $event.stopPropagation();
  }

  onSelectedNode(node: LocationDto) {
    if (this.selectedNode === node) return;

    this.selectedNode = node;
    this.selectedNodeChange.emit(node);
  }

  onExpandChange($event: any) {
    console.log($event);
    if ($event.eventName === 'expand') {
      const node = $event.node;
      if (node?.getChildren().length === 0 && node?.isExpanded) {
        this.loadChildren(node.origin.id).subscribe(res => {
          node.isLeaf = res.totalCount === 0;
          node.addChildren(mapLocationsTreeNode(res.items).map(node => ({ ...node, isLeaf: false } as any)));
        });
      }
    }
  }

  refreshNode(key: string) {
		const node = this.findNode({ id: key }, this.nodes);
    if (node) {
      this.service.get(key).subscribe(res => {
        node.title = res.name;
        node.entity = res;
      });
    }
  }

  reloadChildren(parentId?: string) {
		const node = this.findNode({ id: parentId }, this.nodes);
    if (node) {
      node.isExpanded = true;
      this.loadChildren(parentId).subscribe(res => {
        if (parentId) {
          node.isLeaf = res.totalCount === 0;
          node.children = mapLocationsTreeNode(res.items).map(node => ({ ...node, isLeaf: true } as any));
        } else {
          this.nodes = mapLocationsTreeNode(res.items).map(node => ({ ...node, isLeaf: false } as any));
        }
      });
    }
  }

	findNode(target, nodes) {
		for (const node of nodes) {
			if (node.key === target.id) {
				return node;
			}
			if (node.children) {
				let res = this.findNode(target, node.children);
				if (res) {
					return res;
				}
			}
		}
		return null;
	}
}
