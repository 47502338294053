<h2>{{ 'AdministrationService::Menu:Application' | abpLocalization }}</h2>

<hr class="my-3" />

<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()" [validateOnSubmit]="true">
  <div class="mb-3 form-group">
    <label class="form-label">{{
      'AdministrationService::AssetCodePattern' | abpLocalization
    }}</label>
    <input type="text" class="form-control" formControlName="assetCodePattern" />
  </div>
  <div class="mb-3 form-group">
    <label class="form-label">{{
      'AdministrationService::ReportNumberPattern' | abpLocalization
    }}</label>
    <input type="text" class="form-control" formControlName="reportNumberPattern" />
  </div>
  <div class="mb-3 form-check form-switch">
    <input
      id="inspection-auto-approval"
      type="checkbox"
      class="form-check-input"
      role="switch"
      formControlName="inspectionAutoApproval"
    />
    <label class="form-check-label" for="inspection-auto-approval">{{
      'AdministrationService::InspectionAutoApproval' | abpLocalization
    }}</label>
  </div>

  <hr />

  <button type="submit" class="btn btn-primary" [disabled]="form?.invalid && form?.touched">
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
