import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const CATEGORIES_CATEGORY_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/categories',
        iconClass: 'fas fa-file-alt',
        name: 'RegisterService::Menu:Categories',
        parentName: 'RegisterService::Menu:RegisterService',
        order: 25,
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.Categories && RegisterService.Categories.Edit'
      },
    ]);
  };
}
