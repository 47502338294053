import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { JoinPipe } from './pipes/join.pipe';
import { AuthImagePipe } from './pipes/auth-image.pipe';
import { RegisterSettingsComponent } from './settings/register-settings/register-settings.component';
import { CategoriesTreeComponent } from './categories-tree/categories-tree.component';
import { TreeModule } from '@abp/ng.components/tree';
import { CategoryPickerComponent } from './category-picker/category-picker.component';
import { LocationsTreeComponent } from './locations-tree/locations-tree.component';
import { LocationPickerComponent } from './location-picker/location-picker.component';

@NgModule({
  declarations: [
    JoinPipe,
    AuthImagePipe,
    RegisterSettingsComponent,
    LocationsTreeComponent,
    CategoriesTreeComponent,
    CategoryPickerComponent,
    LocationPickerComponent
  ],
  imports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonXModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    TreeModule
  ],
  exports: [
    JoinPipe,
    AuthImagePipe,
    LocationsTreeComponent,
    CategoriesTreeComponent,
    CategoryPickerComponent,
    LocationPickerComponent
  ],
  providers: []
})
export class SharedModule {}
