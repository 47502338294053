<a role="button" (click)="toggleModal()">
  <i class="bi bi-journal-code"></i>
</a>

<abp-modal [(visible)]="isModalOpen" [options]="{ size: 'lg' }">
  <ng-template #abpHeader>
    <h3>
      {{ 'API Spec' | abpLocalization }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <iframe
      [src]="docUrl"
      width="100%"
      height="900"
      seamless
      target="_top"
      title="Swagger UI"
      class="border-0"
    ></iframe>
  </ng-template>
</abp-modal>
