import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  CustomerCreateDto,
  CustomerDto,
  CustomerUpdateDto,
  CustomerUserDto,
  CustomerUsersUpdateDto,
  GetCustomerUsersInput,
  GetCustomersInput,
} from './models';
import { CustomerLookupRequestDto, LookupDto, LookupRequestDto } from '@proxy/shared';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  apiName = 'RegisterService';
  urlPrefix = '/api/register/customers';
  urlPrefixUser = '/api/register/users';

  create = (input: CustomerCreateDto) =>
    this.restService.request<any, CustomerDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, CustomerDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetCustomersInput) =>
    this.restService.request<any, PagedResultDto<CustomerDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          term: input.term,
          name: input.name,
          accountNo: input.accountNo,
          addressCity: input.addressCity,
          addressStreet1: input.addressStreet1,
          addressStreet2: input.addressStreet2,
          addressPostCode: input.addressPostCode,
          addressRegion: input.addressRegion,
          addressCountry: input.addressCountry,
          email: input.email,
          phone: input.phone,
          notes: input.notes,
          isActive: input.isActive,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getLookup = (input: CustomerLookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/lookup`,
        params: {
          filter: input.filter,
          ids: input.ids,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: CustomerUpdateDto) =>
    this.restService.request<any, CustomerDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getCustomerUsersList = (input: GetCustomerUsersInput) =>
    this.restService.request<any, PagedResultDto<CustomerUserDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefixUser}/${input.userId}/customers`,
        params: {
          userId: input.userId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  assignToUser = (userId: string, input: string[]) =>
    this.restService.request<any, CustomerUserDto[]>(
      {
        method: 'PUT',
        url: `${this.urlPrefixUser}/${userId}/customers`,
        body: input,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
