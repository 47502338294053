import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { isMobile } from '@/shared/utils';

export const QUEUE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/queue',
        name: 'QueueService::Menu:QueueService',
        iconClass: 'fas fa-list',
        order: 80,
        layout: eLayoutType.application,
        requiredPolicy: 'RegisterService.Assets || ServicingService.Inspections',
        invisible: isMobile()
      },
    ]);
  };
}
