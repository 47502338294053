import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const INSPECTIONS_INSPECTION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/inspections/pending',
        iconClass: 'fas fa-file-signature',
        name: 'ServicingService::Menu:PendingInspections',
        parentName: 'ServicingService::Menu:ServicingService',
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.PendingInspections',
        order: 1,
      },
      {
        path: '/inspections/completed',
        iconClass: 'fas fa-file-contract',
        name: 'ServicingService::Menu:CompletedInspections',
        parentName: 'ServicingService::Menu:ServicingService',
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.CompletedInspections',
        order: 5
      },
      {
        path: '/inspections/upcoming',
        iconClass: 'fas fa-calendar-check',
        name: 'ServicingService::Menu:UpcomingInspections',
        parentName: 'ServicingService::Menu:ServicingService',
        layout: eLayoutType.application,
        requiredPolicy: 'ServicingService.UpcomingInspections',
        order: 10
      }
    ]);
  };
}
