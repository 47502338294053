<div class="card h-100">
  <div class="card-header d-flex justify-content-between" *ngIf="!readonly">
    <h5>
      {{ 'RegisterService::Categories' | abpLocalization }}
    </h5>
    <button
      *abpPermission="'RegisterService.Categories.Create'"
      class="btn btn-sm btn-primary"
      (click)="onAddRootNode()"
    >
      <i class="fas fa-plus me-1"></i> {{ 'RegisterService::NewCategory' | abpLocalization }}
    </button>
  </div>

  <div class="card-body" [abpLoading]="isLoading">
    <abp-tree
      #treeRef
      [nodes]="nodes"
      [(expandedKeys)]="expandedKeys"
      [selectedNode]="selectedNode"
      [draggable]="false"
      (selectedNodeChange)="onSelectedNode($event)"
      (nzExpandChange)="onExpandChange($event)"
    >
      <ng-template abpTreeNodeTemplate let-node>
        <div
          class="node-container d-flex flex-row"
          [class.node-selected]="node.origin.id === selectedNode?.id"
          (mouseover)="node.isHovered = true"
          (mouseleave)="node.isHovered = false"
        >
          <i class="fas fa-folder fs-15px text-primary me-1"></i>
          {{ node.title }}
          <div *ngIf="node.isHovered && !readonly" class="ms-auto">
            <i
              class="fas fa-plus fa-lg ms-3"
              *abpPermission="'RegisterService.Categories.Edit'"
              (click)="onAddSubNode(node, $event)"
            ></i>
            <i
              class="fas fa-trash-alt fa-lg ms-2"
              *abpPermission="'RegisterService.Categories.Delete'"
              (click)="onDelete(node, $event)"
            ></i>
          </div>
        </div>
      </ng-template>
    </abp-tree>
    <p *ngIf="!isLoading && !nodes?.length" class="text-muted">
      {{ 'RegisterService::NoCategories' | abpLocalization }}
    </p>
  </div>
</div>
