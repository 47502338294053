import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { Route, Router, RouterModule, Routes } from '@angular/router';
import { saasCreateFormPropContributors } from './tenants/tenant-form-prop-contributors';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'gdpr',
    loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy(
      { createFormPropContributors: saasCreateFormPropContributors }
    )),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'gdpr-cookie-consent',
    loadChildren: () =>
      import('./gdpr-cookie-consent/gdpr-cookie-consent.module').then(
        m => m.GdprCookieConsentModule
      ),
  },
  {
    path: 'countries',
    loadChildren: () => import('./countries/country/country.module').then(m => m.CountryModule),
  },
  { path: 'sites', loadChildren: () => import('./sites/site/site.module').then(m => m.SiteModule) },
  {
    path: 'attribute-definition-groups',
    loadChildren: () =>
      import('./attribute-definitions/attribute-definition.module').then(
        m => m.AttributeDefinitionModule
      ),
  },
  {
    path: 'attribute-definition-groups/create',
    loadChildren: () =>
      import('./attribute-definitions/attribute-definition.module').then(
        m => m.AttributeDefinitionModule
      ),
  },
  {
    path: 'categories',
    loadChildren: () => import('./categories/category.module').then(m => m.CategoryModule),
  },
  {
    path: 'manufacturers',
    loadChildren: () =>
      import('./manufacturers/manufacturer.module').then(m => m.ManufacturerModule),
  },
  {
    path: 'locations',
    loadChildren: () => import('./locations/location.module').then(m => m.LocationModule),
  },
  {
    path: 'assets',
    loadChildren: () => import('./assets/asset.module').then(m => m.AssetModule),
  },
  {
    path: 'staff',
    loadChildren: () => import('./staffs/staff/staff.module').then(m => m.StaffModule),
  },
  {
    path: 'inspection-statuses',
    loadChildren: () =>
      import('./inspection-statuses/inspection-status/inspection-status.module').then(
        m => m.InspectionStatusModule
      ),
  },
  {
    path: 'inspection-types',
    loadChildren: () =>
      import('./inspection-types/inspection-type.module').then(m => m.InspectionTypeModule),
  },
  {
    path: 'inspections',
    loadChildren: () => import('./inspections/inspection.module').then(m => m.InspectionModule),
  },
  {
    path: 'customers',
    loadChildren: () => import('./customers/customer/customer.module').then(m => m.CustomerModule),
  },
  {
    path: 'queue',
    loadChildren: () => import('./queue/queue.module').then(m => m.QueueModule),
  },
  {
    path: 'reports/designer',
    loadChildren: () => import('./reports/report-designer.module').then(m => m.ReportDesignerModule)
  },
  {
    path: 'settings/tenancy',
    loadChildren: () => import('./tenancy/tenancy.module').then(m => m.TenancyModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: '**',
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [AuthGuard, PermissionGuard],
})
export class AppRoutingModule {
  constructor(router: Router) {
    router.config
      .filter(route => !route.data || !route.data.skipGuard)
      .forEach(route => this.addGuard(route));
  }

  private addGuard(route: Route): void {
    route.canActivate = route.canActivate ? [AuthGuard, PermissionGuard].concat(route.canActivate) : [AuthGuard, PermissionGuard];
  }
}
