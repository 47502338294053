import { BaseNode, TreeAdapter } from '@abp/ng.components/tree';
import { ConfigStateService, generatePassword, ListService, PagedResultDto } from '@abp/ng.core';
import {
  Confirmation,
  ConfirmationService,
  getPasswordValidators,
  ToasterService,
} from '@abp/ng.theme.shared';
import {
  ePropType,
  EXTENSIONS_IDENTIFIER,
  FormProp,
  FormPropData,
  generateFormFromProps,
} from '@abp/ng.theme.shared/extensions';
import {
  Component,
  Injector,
  OnInit,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GetIdentityUsersInput,
  IdentityRoleDto,
  IdentityRoleService,
  IdentityUserDto,
  IdentityUserService,
  IdentityUserUpdatePasswordInput,
  OrganizationUnitDto,
  OrganizationUnitService,
  OrganizationUnitWithDetailsDto,
} from '@volo/abp.ng.identity/proxy';
import { finalize, switchMap, take, tap } from 'rxjs/operators';
import { eIdentityComponents, UsersComponent } from '@volo/abp.ng.identity';
import { identityTwoFactorBehaviourOptions } from '@volo/abp.ng.identity';
import { ClaimModalComponent } from '@volo/abp.ng.identity';
import { CustomerDto, CustomerService, CustomerUserDto } from '@proxy/register-service/customers';
import { CustomerLookupRequestDto, LookupDto } from '@proxy/shared';
import { iif, of } from 'rxjs';
import { LookupTypeaheadComponent } from '@volo/abp.commercial.ng.ui';
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  providers: [
    ListService,
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eIdentityComponents.Users,
    },
    {
      provide: UsersComponent,
      useExisting: UserComponent,
    },
  ],
  styles: [
    `
      .mh-35 {
        max-height: 35px;
      }
    `,
  ],
  entryComponents: [ClaimModalComponent],
})
export class UserComponent implements OnInit {

  @ViewChild('customerLookup')
  customerLookup!: LookupTypeaheadComponent;

  data: PagedResultDto<IdentityUserDto> = { items: [], totalCount: 0 };
  form: FormGroup;
  setPasswordForm: FormGroup;
  selected: IdentityUserDto;
  selectedUserRoles: IdentityRoleDto[];
  selectedCustomers: LookupDto<string>[];
  roles: IdentityRoleDto[];
  customers: LookupDto<string>[];
  selectedOrganizationUnits: OrganizationUnitDto[];
  visiblePermissions = false;
  providerKey: string;
  isModalVisible: boolean;
  isSetPasswordModalVisible: boolean;
  modalBusy = false;
  visibleClaims = false;
  claimSubject = {} as { id: string; type: 'roles' | 'users' };
  filters = {} as GetIdentityUsersInput;
  private _selectedCustomerId: string = null;

  organization = {
    response: {} as PagedResultDto<OrganizationUnitWithDetailsDto>,
    nodes: [],
    checkedKeys: [],
    expandedKeys: [],
    selectFn: () => false,
  };

  isLockModalVisible: boolean;

  twoFactor = {
    isModalVisible: false,
    checkboxValue: false,
    isOptional: false,
  };

  lockForm: FormGroup;

  dateTimePickerProps = {
    defaultValue: new Date(),
    displayName: 'AbpIdentity::DisplayName:LockoutEnd',
    validators: () => [Validators.required],
    name: 'lockoutEnd',
    id: 'lockout-end',
    type: ePropType.DateTime,
  } as Partial<FormProp>;

  trackByFn: TrackByFunction<AbstractControl> = (index, item) => Object.keys(item)[0] || index;

  constructor(
    public readonly list: ListService<GetIdentityUsersInput>,
    public confirmationService: ConfirmationService,
    public service: IdentityUserService,
    public fb: FormBuilder,
    public toasterService: ToasterService,
    public injector: Injector,
    public configState: ConfigStateService,
    public roleService: IdentityRoleService,
    public organizationUnitService: OrganizationUnitService,
    public customerService: CustomerService
  ) { }

  ngOnInit() {
    const { key } = identityTwoFactorBehaviourOptions[0];
    this.twoFactor.isOptional =
      this.configState.getFeature('Identity.TwoFactor') === key &&
      this.configState.getSetting('Abp.Identity.TwoFactor.Behaviour') === key;

    this.hookToQuery();

    this.setPasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, ...getPasswordValidators(this.injector)]],
    });

    this.lockForm = this.fb.group({
      lockoutEnd: [new Date(), [Validators.required]],
    });
  }

  public get selectedCustomerId(): string {
    return this._selectedCustomerId;
  }
  public set selectedCustomerId(value: string) {
    this._selectedCustomerId = value;
    setTimeout(() => {
      this.onAssign(value);
    }, 0);
  }

  get roleGroups(): FormGroup[] {
    return ((this.form.get('roleNames') as FormArray)?.controls as FormGroup[]) || [];
  }

  get customerUsers(): FormGroup[] {
    return ((this.form.get('customerUsers') as FormArray)?.controls as FormGroup[]) || [];
  }

  onVisiblePermissionChange = (value: boolean) => {
    this.visiblePermissions = value;
  };

  clearFilters() {
    this.filters = {} as GetIdentityUsersInput;
    this.list.get();
  }

  private hookToQuery() {
    this.list
      .hookToQuery(query => this.service.getList({ ...query, ...this.filters }))
      .subscribe(res => (this.data = res));
  }

  buildForm() {
    const data = new FormPropData(this.injector, this.selected);
    this.form = generateFormFromProps(data);

    const extraPropGroup = this.form.get('extraProperties') as FormGroup;
    extraPropGroup.addControl('CustomerIds', this.fb.array<string>(this.selected.extraProperties?.CustomerIds || []));

    this.service.getAssignableRoles().subscribe(({ items }) => {
      this.roles = items;
      this.form.addControl(
        'roleNames',
        this.fb.array(
          this.roles.map(role =>
            this.fb.group({
              [role.name]: [
                this.selected.id
                  ? !!this.selectedUserRoles?.find(userRole => userRole.id === role.id)
                  : role.isDefault,
              ],
            })
          )
        )
      );
    });

    this.service.getAvailableOrganizationUnits().subscribe(res => {
      this.organization.response = res;
      this.organization.nodes = new TreeAdapter(res.items as BaseNode[]).getTree();
      this.organization.expandedKeys = res.items.map(item => item.id);
      this.organization.checkedKeys = this.selectedOrganizationUnits.map(unit => unit.id);
    });
  }

  openModal() {
    this.buildForm();
    this.isModalVisible = true;
  }

  onAdd() {
    this.selected = {} as IdentityUserDto;
    this.selectedUserRoles = [];
    this.selectedCustomers = [];
    this.selectedOrganizationUnits = [];
    this.openModal();
  }

  onEdit(id: string) {
    this.service
      .get(id)
      .pipe(
        tap(user => {
          if (!user.extraProperties?.CustomerIds)
            user.extraProperties.CustomerIds = [];
          this.selected = user;
        }),
        switchMap(() => this.service.getRoles(id)),
        tap(res => (this.selectedUserRoles = res.items || [])),
        switchMap(() => this.service.getOrganizationUnits(id)),
        tap(res => (this.selectedOrganizationUnits = res)),
        switchMap(() => iif(() => this.selected?.extraProperties?.CustomerIds?.length > 0,
          this.customerService.getLookup({
            ids: this.selected.extraProperties?.CustomerIds,
            maxResultCount: 100,
          }), of({ items: [] }))
        ),
        tap(res => (this.selectedCustomers = res.items)),
        take(1)
      )
      .subscribe(() => this.openModal());
  }

  save() {
    if (!this.form.valid) return;
    this.modalBusy = true;

    const { id } = this.selected;
    const { roleNames } = this.form.value;
    // const { customerUsers } = this.form.value;

    const mappedRoleNames =
      roleNames?.filter(role => !!role[Object.keys(role)[0]])?.map(role => Object.keys(role)[0]) ||
      [];

    // const customerIds = customerUsers
    //   .filter(cu => !!cu[Object.keys(cu)[0]])
    //   .map(cu => (
    //     cu.id
    //   ));

    (id
      ? this.service.update(id, {
        ...this.selected,
        ...this.form.value,
        roleNames: mappedRoleNames,
        // extraProperties: {
        //   CustomerIds: customerIds
        // },
        // customerUsers: customerIds,
        organizationUnitIds: this.organization.checkedKeys,
      })
      : this.service.create({
        ...this.form.value,
        roleNames: mappedRoleNames,
        // extraProperties: {
        //   CustomerIds: customerIds
        // },
        // customerUsers: customerIds,
        organizationUnitIds: this.organization.checkedKeys,
      })
    )
      .pipe(
        finalize(() => (this.modalBusy = false))
      )
      .subscribe(() => {
        this.list.get();
        this.isModalVisible = false;
        this.toasterService.success('AbpIdentity::UserSaved');
      });
  }

  delete(id: string, userName: string) {
    this.confirmationService
      .warn('AbpIdentity::UserDeletionConfirmationMessage', 'AbpIdentity::AreYouSure', {
        messageLocalizationParams: [userName],
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.service.delete(id).subscribe(() => this.list.get());
        }
      });
  }

  onManageClaims(id: string) {
    this.claimSubject = {
      id,
      type: 'users',
    };

    this.visibleClaims = true;
  }

  unlock(id: string) {
    this.service.unlock(id).subscribe(() => {
      this.toasterService.success('AbpIdentity::UserUnlocked');
      this.list.get();
    });
  }

  openPermissionsModal(providerKey: string) {
    this.providerKey = providerKey;
    setTimeout(() => {
      this.visiblePermissions = true;
    }, 0);
  }

  setPassword() {
    if (this.setPasswordForm.invalid) return;

    this.modalBusy = true;
    this.service
      .updatePassword(
        this.selected.id,
        this.setPasswordForm.value as IdentityUserUpdatePasswordInput
      )
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.isSetPasswordModalVisible = false;
        this.selected = {} as IdentityUserDto;
        this.setPasswordForm.reset();
      });
  }

  generatePassword() {
    this.setPasswordForm.get('newPassword').setValue(generatePassword());
  }

  lock() {
    const { lockoutEnd } = this.lockForm.value;

    this.modalBusy = true;
    this.service
      .lock(this.selected.id, lockoutEnd.toISOString())
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.isLockModalVisible = false;
        this.lockForm.reset({
          lockoutEnd: new Date(),
        });
        this.list.get();
      });
  }

  setTwoFactor() {
    this.modalBusy = true;
    this.service
      .setTwoFactorEnabled(this.selected.id, this.twoFactor.checkboxValue)
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => (this.twoFactor.isModalVisible = false));
  }

  onRoleClick(e, roleName) {
    if (roleName === 'customer') {
      if (this.isCustomer) {
        // Uncheck staff roles
        for (const roleGroup of this.roleGroups) {
          if (roleGroup.value['customer'] === undefined) {
            const val = roleGroup.value;
            roleGroup.setValue({ [Object.keys(val)[0]]: false });
          }
        }
      }
    } else {
      // Uncheck customer role
      for (const roleGroup of this.roleGroups) {
        if (roleGroup.value['customer'] !== undefined) {
          const val = roleGroup.value;
          roleGroup.setValue({ [Object.keys(val)[0]]: false });
        }
      }
    }
  }

  getCustomerLookup = (input: CustomerLookupRequestDto) => this.customerService.getLookup(input).pipe(tap((res) => this.customers = res.items));

  onAssign(customerId) {
    if (!customerId) return;
    if (this.customerIdsFormArray.value.find(id => id === customerId)) return;
    this.customerIdsFormArray.push(new FormControl(customerId));
    this.selectedCustomers = [...this.selectedCustomers, { id: customerId, displayName: this.customers.find(c => c.id === customerId).displayName }];
    this.customerLookup.model = null;
  }

  onUnassign(customerId) {
    if (!customerId) return;
    this.customerIdsFormArray.removeAt(this.customerIdsFormArray.controls.findIndex(c => c.value === customerId));
    this.selectedCustomers = this.selectedCustomers.filter(c => c.id !== customerId);
  }

  get customerIdsFormArray(): FormArray {
    return this.form.get(['extraProperties', 'CustomerIds']) as FormArray;
  }

  get extraPropertiesFormGroup(): FormGroup {
    return this.form.get('extraProperties') as FormGroup;
  }

  get isCustomer() {
    const { roleNames } = this.form.value;
    return roleNames?.find(r => r['customer'] === true);
  }

  get isStaff() {
    return !this.isCustomer;
  }
}
