import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
  pure: false
})
export class JoinPipe implements PipeTransform {
  transform(value: string[], separator: string): string {
    if (!value) {
      return null;
    }
    return value.filter(v => !!v).join(separator);
  }
}
