<div class="selector-container">
  <label class="form-label">
    {{ 'ServicingService::Location' | abpLocalization }}
  </label>

  <div>
    <button
      *ngIf="!name"
      type="button"
      class="btn btn-secondary"
      (click)="onSelectClicked()"
      [disabled]="!customerId"
    >
      {{ 'AbpUi::Select' | abpLocalization }}
    </button>

    <div *ngIf="name">
      <span>{{ name }}</span>
      <i *ngIf="!readonly" class="fas fa-times fa-lg ms-2" (click)="onRemove()"></i>
    </div>
  </div>
</div>

<abp-modal [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h5>
      {{ 'RegisterService::Locations' | abpLocalization }}
    </h5>
  </ng-template>

  <ng-template #abpBody>
    <div id="table-filter" class="data-tables-filter mb-2">
      <label
        ><input
          type="search"
          class="form-control"
          [placeholder]="'AbpUi::PagerSearch' | abpLocalization"
          [ngModel]="term"
          (input)="onInputChange($event)"
      /></label>
    </div>
    <div *ngIf="term">
      <ngx-datatable
        [rows]="data.items"
        [count]="data.totalCount"
        [list]="list"
        [messages]="{ emptyMessage: 'No locations' }"
        default
        [headerHeight]="0"
      >
        <ngx-datatable-column [maxWidth]="150" [width]="150" [sortable]="false">
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <button class="btn btn-primary btn-sm" type="button" (click)="onPick(row)">
              {{ '::Pick' | abpLocalization }}
            </button>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.displayName }}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>

    <app-locations-tree
      *ngIf="!term"
      [customerId]="customerId"
      [readonly]="true"
      [ignoreSiteId]="true"
      [selectedNode]="selectedLocationTemp"
      (selectedNodeChange)="onSelectedNodeChange($event)"
    >
    </app-locations-tree>
  </ng-template>

  <ng-template #abpFooter>
    <button *ngIf="!term" type="button" class="btn btn-primary" (click)="onSelect()">
      {{ 'AbpUi::Select' | abpLocalization }}
    </button>

    <button type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
