import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';
import { QueueComponent } from './queue/queue.component';
import { QueueRoutingModule } from './queue-routing.module';
import { QueueBottomComponent } from './queue-bottom/queue-bottom.component';
import { SharedModule } from '@/shared/shared.module';
import { LpxContextMenuModule, } from '@volosoft/ngx-lepton-x';
import { LpxClickOutsideModule, LpxNavbarModule, } from '@volo/ngx-lepton-x.core';

@NgModule({
  declarations: [QueueComponent, QueueBottomComponent],
  imports: [
    QueueRoutingModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    PageModule,
    SharedModule,
    LpxClickOutsideModule,
    LpxContextMenuModule,
    LpxNavbarModule,
  ],
})
export class QueueModule {}
