import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription, EMPTY, map, tap, Observable, of } from 'rxjs';
import {
  ICON_MAP,
  LpxNavbarItem,
  NavbarService,
  UserProfileService,
  UserProfile
} from '@volo/ngx-lepton-x.core';
import { AppMobileNavbarService } from './mobile-navbar.service';
import { MobileMenuTranslate } from './mobile-menu-translate.enum';

@Component({
  selector: 'lpx-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [AppMobileNavbarService],
})
export class AppMobileNavbarComponent implements OnInit, OnDestroy {
  user$: any;

  menuVisible = false;

  navTabs!: LpxNavbarItem[];
  navTabs2!: LpxNavbarItem[];
  selectedMenuItems!: LpxNavbarItem[];
  groupedItems$: Observable<{
        group: string;
        items: LpxNavbarItem[];
    }[]>;

  navbarMenuItems!: LpxNavbarItem[];
  settingsMenuItems!: LpxNavbarItem[];
  profileMenuItems?: LpxNavbarItem[];
  user: UserProfile;

  activeMenu = '';

  icons = ICON_MAP;

  sub = new Subscription();

  settingsTitle = MobileMenuTranslate.SettingsTitle;

  toggleClass = '';

  protected getGroupedItems() {
    return this.navbarService.groupedNavbarItems$.pipe(
      map((groupItems) =>
        groupItems?.map(({ group, items }) => ({
          group,
          items: items.filter((item) => !item.showOnMobileNavbar),
        }))
      )
    );
  }
  display = (data: any) => {
    console.log(data);
  }

  protected setNavTabs = (items: LpxNavbarItem[]) => {
    const flatItems = items.flatMap(item => item.children.length ? [item, ...item.children] : item);
    this.navTabs = flatItems.filter((item) => item.link === '/assets' || item.link === '/home');
    this.navTabs2 = flatItems.filter((item) => item.link === '/queue' || item.link === '/inspections/pending')
                              .map((item) => {
                                item.showOnMobileNavbar = true;
                                return item;
                              });
    this.setNavbarMenuItems(items);
  };

  protected setNavbarMenuItems = (items: LpxNavbarItem[]) => {
    this.navbarMenuItems = items?.filter(
      (item) => !this.navTabs.includes(item)
    );
  };

  protected setSettingsMenuItems = (items: LpxNavbarItem[]) => {
    this.settingsMenuItems = items;
  };

  protected setProfileActions = (items?: LpxNavbarItem[]) => {
    this.profileMenuItems = items;
  };

  constructor(
    private navbarService: NavbarService,
    private userProfileService: UserProfileService,
    private service: AppMobileNavbarService,
  ) {
    this.user$ = this.userProfileService.user$;
    this.groupedItems$ = this.getGroupedItems();
  }

  ngOnInit(): void {
    this.sub.add(this.navbarService.navbarItems$.subscribe(this.setNavTabs));
    this.sub.add(this.service.settings$.subscribe(this.setSettingsMenuItems));
    this.sub.add(
      this.service.userProfileActions$.subscribe(this.setProfileActions)
    );
    this.sub.add(this.user$.subscribe((user) => this.user = user));
  }

  onSubnavbarExpand(menuItem: LpxNavbarItem): void {
    if (menuItem.expanded) {
      this.navbarMenuItems
        ?.filter((item) => item !== menuItem)
        .forEach((item) => (item.expanded = false));
    }
  }

  toggleMenu(type: string, menuItems?: LpxNavbarItem[]) {
    if (!type || !menuItems || !menuItems.length) {
      this.closeMenu();
      return;
    }

    this.toggleClass = '';
    if (type === this.activeMenu) {
      if (this.menuVisible) {
        this.closeMenu();
      }
    } else {
      this.menuVisible = true;
      this.activeMenu = type;
      this.groupedItems$ = type === 'navbar' ? this.getGroupedItems() : EMPTY;
      this.selectedMenuItems = menuItems;
    }
  }

  toggleNavbarMenu() {
    // this.toggleMenu('navbar', this.navbarMenuItems);
    this.toggleMenu('navbar', this.navbarMenuItems.concat([{ children: this.profileMenuItems, text: this.user.userName, expanded: false, icon: 'fa fa-user' }]));
    this.toggleClass = this.menuVisible ? 'lpx-mobile-menu-toggle-open' : '';
  }
  closeMenu() {
    this.toggleClass = '';
    this.activeMenu = '';
    this.menuVisible = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
