import { Component, OnInit, ViewChild } from '@angular/core';
import { ABP, PagedResultDto } from '@abp/ng.core';
import { Observable, of as rxOf } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { QueueService } from '@proxy/queue-service/queue';
import { QueueItemDto, GetQueueItemsInput } from '@proxy/queue-service/queue';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@/store/models/state.model';
import { UpdateQueueSizeAction } from '@/store/actions/queue.actions';
import { ContextMenuComponent, ContextMenuToggleDirective } from '@volosoft/ngx-lepton-x';

@Component({
  selector: 'app-queue-bottom',
  templateUrl: './queue-bottom.component.html',
  styleUrls: ['./queue-bottom.component.scss'],
})
export class QueueBottomComponent implements OnInit {
  @ViewChild(ContextMenuComponent, { static: false })
  ctxMenu!: ContextMenuComponent;

  queueSize$: Observable<number>;
  total: number;
  queue$: Observable<QueueItemDto[]>;
  data: PagedResultDto<QueueItemDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetQueueItemsInput;
  isQueueDropdownOpen = false;

  constructor(private service: QueueService, private router: Router, private store: Store<State>) {}

  ngOnInit(): void {
    this.queueSize$ = this.store.select(store => store.queue.size);
    this.queueSize$.subscribe(data => (this.total = data));
    // this.store.select((store) => store.queue).subscribe(queue =>
    //   this.data.totalCount = queue.size
    // );

    // const getData = (query: ABP.PageQueryParams) =>
    //   this.service.getList({
    //     ...query,
    //     ...this.filters,
    //     term: query.filter,
    //   });
    // const setData = (list: PagedResultDto<SiteDetailsDto>) => (this.data = list);
    // this.list.hookToQuery(getData).subscribe(setData);
    const query: ABP.PageQueryParams = {
      sorting: 'creationTime desc',
      skipCount: 0,
      maxResultCount: 5,
    };
    // const getData = () =>
    //   this.service.getList({
    //     ...query,
    //     ...this.filters,
    //     term: query.filter,
    //   });

    // this.queue$ = getData().pipe(tap((d) => this.data = d), map(d => d.items));
    this.queue$ = this.service
      .getList({
        ...query,
        ...this.filters,
        term: query.filter,
      })
      .pipe(
        tap(d => (this.data = d)),
        map(d => d.items.reverse())
      );
  }

  toggleQueueDropdown(event: Event): void {
    event.stopPropagation(); // Prevents the dropdown from closing immediately after opening
    this.isQueueDropdownOpen = !this.isQueueDropdownOpen;
  }

  closeQueueDropdown(): void {
    this.isQueueDropdownOpen = false;
  }

  onEmptyQueueClick(): void {
    // Your logic for emptying the queue
    this.closeQueueDropdown();
  }

  // get queueSize() {
  //   return this.data?.totalCount ?? 0;
  // }

  // get _items$(): Observable<QueueItemDto[]> {
  //   // const query: ABP.PageQueryParams = {
  //   //     sorting: "creationTime desc",
  //   //     skipCount: 0,
  //   //     maxResultCount: 5
  //   // };
  //   // const getData = () =>
  //   //   this.service.getList({
  //   //     ...query,
  //   //     ...this.filters,
  //   //     term: query.filter,
  //   //   });

  //   // return getData().pipe(tap((d) => this.data = d), map(d => d.items));

  //   // return of([
  //   //   { assetId: '1234', assetCode: '1234', assetPrimaryID: '1234' } as QueueItemDto,
  //   //   { assetId: '1234', assetCode: '1234', assetPrimaryID: '1234' } as QueueItemDto,
  //   //   { assetId: '1234', assetCode: '1234', assetPrimaryID: '1234' } as QueueItemDto])
  //   //   .pipe(tap((d) => { this.data.items = d; this.data.totalCount = 3 }), map(d => d));

  //   return of([
  //     { assetId: '1234', assetCode: '1234', assetPrimaryID: '1234' } as QueueItemDto,
  //     { assetId: '1234', assetCode: '1234', assetPrimaryID: '1234' } as QueueItemDto,
  //     { assetId: '1234', assetCode: '1234', assetPrimaryID: '1234' } as QueueItemDto])
  //     .pipe(tap((d) => {
  //       this.store.dispatch(
  //         new UpdateQueueSizeAction(d.length)
  //       );
  //     }));
  // }

  public onItemClick(item: QueueItemDto) {
    this.router.navigate(['/assets/view', item.assetId]);
    this.closeQueueDropdown();
  }

  toggleCtxMenu(): void {
    this.ctxMenu.toggle();
  }
}
