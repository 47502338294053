<abp-page [title]="'QueueService::Queue' | abpLocalization">
  <abp-page-toolbar-container class="col">
    <div class="text-lg-end pt-2"></div>
  </abp-page-toolbar-container>

  <abp-advanced-entity-filters localizationSourceName="QueueService" [list]="list">
    <abp-advanced-entity-filters-form>
      <form #filterForm (keyup.enter)="list.get()">
        <div class="row">
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="assetPrimaryIDFilter">
                {{ 'QueueService::AssetPrimaryID' | abpLocalization }}
              </label>
              <input
                id="assetPrimaryIDFilter"
                class="form-control"
                [(ngModel)]="filters.assetPrimaryID"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="assetSecondaryIDFilter">
                {{ 'QueueService::AssetSecondaryID' | abpLocalization }}
              </label>
              <input
                id="assetSecondaryIDFilter"
                class="form-control"
                [(ngModel)]="filters.assetSecondaryID"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="assetDescriptionFilter">
                {{ 'QueueService::AssetDescription' | abpLocalization }}
              </label>
              <input
                id="assetDescriptionFilter"
                class="form-control"
                [(ngModel)]="filters.assetDescription"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="customerNameFilter">
                {{ 'QueueService::CustomerName' | abpLocalization }}
              </label>
              <input
                id="customerNameFilter"
                class="form-control"
                [(ngModel)]="filters.customerName"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto">
            <div class="mb-3">
              <label class="form-label" for="siteNameFilter">
                {{ 'QueueService::SiteName' | abpLocalization }}
              </label>
              <input
                id="siteNameFilter"
                class="form-control"
                [(ngModel)]="filters.siteName"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto d-flex align-items-end">
            <div class="mb-3">
              <app-location-picker
                (nodeSelected)="filters.locationName = $event.name; list.get()"
                #locationPicker
              />
            </div>
          </div>
          <div class="col-12 col-sm-auto align-self-end mb-3">
            <div class="row">
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                  <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                </button>
              </div>
              <div class="col-6 col-sm-auto d-grid">
                <button type="button" class="btn btn-primary" (click)="list.get()">
                  <span>{{ 'AbpUi::Refresh' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </abp-advanced-entity-filters-form>
  </abp-advanced-entity-filters>

  <div class="card">
    <div class="card-body">
      <ngx-datatable
        [rows]="data.items"
        [count]="data.totalCount"
        [list]="list"
        [loadingIndicator]="isBusy"
        [selected]="selected"
        [selectionType]="SelectionType.checkbox"
        [selectAllRowsOnPage]="true"
        (select)="onSelect($event)"
        default
      >
        <ngx-datatable-column
          [width]="30"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [headerCheckboxable]="true"
          [checkboxable]="true"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'QueueService::AssetPrimaryID' | abpLocalization }}"
          prop="assetPrimaryID"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.assetPrimaryID }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'QueueService::AssetSecondaryID' | abpLocalization }}"
          prop="assetSecondaryID"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.assetSecondaryID }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'QueueService::AssetDescription' | abpLocalization }}"
          prop="assetDescription"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.assetDescription }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'QueueService::CustomerName' | abpLocalization }}"
          prop="customerName"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.customerName }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'QueueService::SiteName' | abpLocalization }}"
          prop="siteName"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.siteName }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'QueueService::CurrentLocation' | abpLocalization }}"
          prop="currentLocation"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.locationName }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'QueueService::NextInspectionOn' | abpLocalization }}"
          prop="nextInspectionOn"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.nextInspectionOn | date }}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>

  <abp-card-footer>
    <div class="modal-footer">
      <abp-button
        *abpPermission="'ServicingService.Inspections.Create'"
        [disabled]="!data.items.length"
        iconClass="fa fa-check"
        (click)="inspectAll()"
      >
        {{ 'AbpUi::Inspect All' | abpLocalization }}
      </abp-button>
      <abp-button
        *abpPermission="'RegisterService.Assets.Edit'"
        [disabled]="!selected.length"
        iconClass="fa fa-check"
        (click)="onMakeActive()"
      >
        {{ 'AbpUi::Make Active' | abpLocalization }}
      </abp-button>
      <abp-button
        *abpPermission="'RegisterService.Assets.Edit'"
        [disabled]="!selected.length"
        iconClass="fa fa-check"
        (click)="onMakeInActive()"
      >
        {{ 'AbpUi::Make Inactive' | abpLocalization }}
      </abp-button>
      <abp-button
        buttonClass="btn btn-danger"
        iconClass="fa fa-trash-alt"
        (click)="onDequeue()"
        [disabled]="!selected.length"
      >
        {{ 'AbpUi::Remove Selected' | abpLocalization }}
      </abp-button>
      <abp-button
        buttonClass="btn btn-danger"
        iconClass="fa fa-trash-alt"
        (click)="onClear()"
        [disabled]="!data.items.length || selected.length"
      >
        {{ 'AbpUi::Remove All' | abpLocalization }}
      </abp-button>
      <button type="button" class="btn btn-secondary" (click)="onBundleCerts()">
        <i class="fa fa-file-archive fa-lg me-1" aria-hidden="true"></i>
        {{ '::BundleCerts' | abpLocalization }}
      </button>
    </div>
  </abp-card-footer>
</abp-page>
