import { Environment } from '@abp/ng.core';

const proto = window.location.protocol;
const baseUrl = `${proto}//{0}.app.sitecertapp.com`;
const apiUrl = `${proto}//{0}.api.sitecertapp.com`;
const authUrl = `${proto}//{0}.auth.sitecertapp.com/`;
const oAuthConfig = {
    issuer: authUrl,
    redirectUri: baseUrl,
    clientId: 'Sitecert_App',
    responseType: 'code',
    scope: [
      'offline_access',
      'openid',
      'profile',
      'email',
      'phone',
      'AccountService',
      'IdentityService',
      'AdministrationService',
      'SaasService',
      'RegisterService',
      'ServicingService',
      'CertReportService',
      'QueueService'
    ].join(' '),
    requireHttps: true,
    strictDiscoveryDocumentValidation: true
  };

export const environment = {
  production: true,
  application: {
    baseUrl,
    name: 'Sitecert',
  },
  oAuthConfig,
  apis: {
    default: {
      url: apiUrl,
      rootNamespace: 'Sitecert',
    }
  }
} as Environment;
