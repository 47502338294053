import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryDetailsDto, CategoryService } from '@proxy/register-service/categories';
import { Subject, debounceTime, distinctUntilChanged, of } from 'rxjs';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-category-picker',
  templateUrl: './category-picker.component.html',
  styleUrls: ['./category-picker.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
})
export class CategoryPickerComponent implements OnInit {
  inputChanged: Subject<string> = new Subject<string>();
  @Input() name: string;
  @Input() readonly: boolean = false;
  @Output() nodeSelected = new EventEmitter<CategoryDetailsDto>();

  term = null;
  selectedCategory?: CategoryDetailsDto = null;
  selectedCategoryTemp?: CategoryDetailsDto = null;
  isModalOpen = false;
  isLoading = false;

  data: PagedResultDto<CategoryDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  constructor(public readonly list: ListService, public readonly service: CategoryService) {}

  ngOnInit(): void {
    this.inputChanged.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(_ => this.list.filter = this.term);

    const getData = (query: ABP.PageQueryParams) =>
      query?.filter ?
        this.service.getLookup({
          ...query,
          maxResultCount: 20
        } as any) : of({ totalCount: 0, items: [] });

    const setData = list => (this.data = list);

    this.list.hookToQuery(getData).subscribe(setData);
  }

  onSelectedNodeChange($event: CategoryDetailsDto) {
    this.selectedCategoryTemp = $event;
  }

  onSelectClicked() {
    this.name = null;
    this.isModalOpen = true;
  }

  onSelect() {
    this.selectedCategory = this.selectedCategoryTemp;
    this.selectedCategoryTemp = null;
    this.name = this.selectedCategory.fullName;
    this.isModalOpen = false;
    this.nodeSelected.emit(this.selectedCategory);
  }

  onCancel() {
    this.name = null;
    this.selectedCategoryTemp = null;
    this.term = null;
    this.list.filter = null;
    this.isModalOpen = false;
  }

  onRemove() {
    this.selectedCategory = null;
    this.selectedCategoryTemp = null;
    this.name = null;
    this.nodeSelected.emit(this.selectedCategory);
  }

  onPick(row) {
    this.selectedCategoryTemp = { ...row, fullName: row?.displayName };
    this.onSelect();
    this.term = null;
  }

  onInputChange(evnt: any) {
    this.term = evnt.target.value;
    this.inputChanged.next(this.term);
  }

  public clear() {
    this.onRemove();
  }
}
