import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetLocationsInput, LocationCreateDto, LocationDto, LocationUpdateDto, LocationDetailsDto } from './models';
import { EMPTY_UUID, type LookupDto, type LookupRequestDto } from '@proxy/shared/models';
import { LocationLookupRequestDto } from '../shared';
import { AttachmentCreateDto, AttachmentDto, AttachmentUpdateDto, GetAttachmentsInput } from '@proxy/attachments';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  apiName = 'RegisterService';

  attach = (id: string, input: AttachmentCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttachmentDto>(
      {
        method: 'POST',
        url: `/api/register/locations/${id}/attachments`,
        body: input.file,
      },
      { apiName: this.apiName, ...config }
    );

  create = (input: LocationCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'POST',
        url: '/api/register/locations',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/register/locations/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  downloadAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}/attachments/${attachmentId}/download`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttachmentDto>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}/attachments/${attachmentId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getAttachments = (
    id: string,
    input: GetAttachmentsInput,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, PagedResultDto<AttachmentDto>>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}/attachments`,
        params: {
          term: input.term,
          title: input.title,
          contentType: input.contentType,
          fileName: input.fileName,
          fileUrl: input.fileUrl,
          folder: input.folder,
          ownerType: input.ownerType,
          ownerId: input.ownerId,
          isActive: input.isActive,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getDetails = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationDetailsDto>(
      {
        method: 'GET',
        url: `/api/register/locations/${id}/details`,
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetLocationsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LocationDto>>(
      {
        method: 'GET',
        url: '/api/register/locations',
        params: {
          term: input.term,
          name: input.name,
          addressCity: input.addressCity,
          addressStreet1: input.addressStreet1,
          addressStreet2: input.addressStreet2,
          addressPostCode: input.addressPostCode,
          addressRegion: input.addressRegion,
          addressCountry: input.addressCountry,
          customerId: input.customerId,
          email: input.email,
          positionLatitudeMin: input.positionLatitudeMin,
          positionLatitudeMax: input.positionLatitudeMax,
          positionLongitudeMin: input.positionLongitudeMin,
          positionLongitudeMax: input.positionLongitudeMax,
          isActive: input.isActive,
          tag: input.tag,
          siteId: input.siteId,
          parentId: input.parentId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getChildren = (siteId: string, customerId: string, parentId?: string) =>
    this.restService.request<any, PagedResultDto<LocationDto>>(
      {
        method: 'GET',
        url: `/api/register/locations`,
        params: {
          siteId: siteId,
          customerId: customerId,
          parentId: parentId ?? EMPTY_UUID,
          maxResultCount: 1000
        }
      },
      { apiName: this.apiName }
    );

  getLookup = (input: LocationLookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/register/locations/lookup',
        params: {
          customerId: input.customerId,
          siteId: input.siteId,
          parentId: input.parentId,
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  removeAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/register/locations/${id}/attachments/${attachmentId}`,
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: LocationUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LocationDto>(
      {
        method: 'PUT',
        url: `/api/register/locations/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  updateAttachment = (
    id: string,
    attachmentId: string,
    input: AttachmentUpdateDto,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, AttachmentDto>(
      {
        method: 'PUT',
        url: `/api/register/locations/${id}/attachments/${attachmentId}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  // create = (input: LocationCreateDto) =>
  //   this.restService.request<any, LocationDto>({
  //     method: 'POST',
  //     url: '/api/register/locations',
  //     body: input,
  //   },
  //   { apiName: this.apiName });

  // delete = (id: string) =>
  //   this.restService.request<any, void>({
  //     method: 'DELETE',
  //     url: `/api/register/locations/${id}`,
  //   },
  //   { apiName: this.apiName });

  // get = (id: string) =>
  //   this.restService.request<any, LocationDto>({
  //     method: 'GET',
  //     url: `/api/register/locations/${id}`,
  //   },
  //   { apiName: this.apiName });

  // getLookup = (input: LocationLookupRequestDto) =>
  //   this.restService.request<any, PagedResultDto<LookupDto<string>>>({
  //     method: 'GET',
  //     url: '/api/register/locations/lookup',
  //     params: { siteId: input.siteId, customerId: input.customerId, filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
  //   },
  //   { apiName: this.apiName });

  // getList = (input: GetLocationsInput) =>
  //   this.restService.request<any, PagedResultDto<LocationDetailsDto>>({
  //     method: 'GET',
  //     url: '/api/register/locations',
  //     params: {
  //       term: input.term,
  //       name: input.name,
  //       addressCity: input.addressCity,
  //       addressStreet1: input.addressStreet1,
  //       addressStreet2: input.addressStreet2,
  //       addressPostCode: input.addressPostCode,
  //       addressRegion: input.addressRegion,
  //       email: input.email,
  //       positionLatitudeMin: input.positionLatitudeMin,
  //       positionLatitudeMax: input.positionLatitudeMax,
  //       positionLongitudeMin: input.positionLongitudeMin,
  //       positionLongitudeMax: input.positionLongitudeMax,
  //       isActive: input.isActive,
  //       tags: input.tags,
  //       countryId: input.countryId,
  //       siteId: input.siteId,
  //       parentId: input.parentId,
  //       sorting: input.sorting,
  //       skipCount: input.skipCount,
  //       maxResultCount: input.maxResultCount
  //     },
  //   },
  //     { apiName: this.apiName });

  getSiteLookup = (input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/register/locations/site-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  // getDetails = (id: string) =>
  //   this.restService.request<any, LocationDetailsDto>({
  //     method: 'GET',
  //     url: `/api/register/locations/${id}/details`,
  //   },
  //   { apiName: this.apiName });

  // update = (id: string, input: LocationUpdateDto) =>
  //   this.restService.request<any, LocationDto>({
  //     method: 'PUT',
  //     url: `/api/register/locations/${id}`,
  //     body: input,
  //   },
  //   { apiName: this.apiName });

  deactivate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/register/locations/${id}/deactivate`,
      },
      { apiName: this.apiName }
    );

  activate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `/api/register/locations/${id}/activate`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
