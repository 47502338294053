import { ABP, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { filter, switchMap } from 'rxjs/operators';
import { GetQueueItemsInput, QueueItemDto } from '@proxy/queue-service/queue';
import { QueueService } from '@proxy/queue-service/queue/queue.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { AssetService } from '@proxy/register-service/assets';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@/store/models/state.model';
import { UpdateQueueSizeAction } from '@/store/actions/queue.actions';
import { CertService } from '@proxy/cert-report-service';
import fileDownload from 'js-file-download';

@Component({
  selector: 'app-asset-queue',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss'],
})
export class QueueComponent implements OnInit {
  data: PagedResultDto<QueueItemDto> = {
    items: [],
    totalCount: 0,
  };

  filters = {} as GetQueueItemsInput;
  form: FormGroup;
  isFiltersHidden = true;
  isBusy = false;
  selected?: QueueItemDto[] = [];

  isAssetActive: boolean = false;

  SelectionType = SelectionType;

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: QueueService,
    public readonly assetService: AssetService,
    private readonly certService: CertService,
    private confirmation: ConfirmationService,
    private router: Router,
    private store: Store<State>,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
        term: query.filter,
      });
    const setData = (list: PagedResultDto<QueueItemDto>) => {
      this.data = list;
      this.store.dispatch(new UpdateQueueSizeAction(this.data.totalCount));
    };

    this.list.hookToQuery(getData).subscribe(setData);
  }

  clearFilters() {
    this.filters = {} as GetQueueItemsInput;
    this.list.get();
  }

  onInspect(record: QueueItemDto) {
    this.router.navigate(['/inspections/create'], { queryParams: { assetId: record.assetId } });
  }

  onAssetStateChange(ids: string[], state?: boolean) {
    this.assetService.changeState({ ids: ids, isActive: state }).subscribe(p => p);
  }

  onMakeActive() {
    const ids = this.selected.map(asset => asset.assetId);

    this.confirmation
      .warn('QueueService::ActivateConfirmationMessage', 'QueueService::AreYouSure', {
        messageLocalizationParams: [ids.length.toString()],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.assetService.changeState({ ids: ids, isActive: true }))
      )
      .subscribe(
        () => {
          // Handle successful activation if needed
        },
        error => {
          console.error('Error activating assets', error);
          // Handle error if needed
        }
      );
  }

  onMakeInActive() {
    const ids = this.selected.map(asset => asset.assetId);
    this.confirmation
      .warn('QueueService::InactivateConfirmationMessage', 'QueueService::AreYouSure', {
        messageLocalizationParams: [ids.length.toString()],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.assetService.changeState({ ids: ids, isActive: false }))
      )
      .subscribe(
        () => {
          // Handle successful activation if needed
        },
        error => {
          console.error('Error activating assets', error);
          // Handle error if needed
        }
      );
  }

  onDequeue(record?: QueueItemDto) {
    this.confirmation
      .warn('QueueService::DeleteConfirmationMessage', 'QueueService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() =>
          record
            ? this.service.dequeue(record.id)
            : this.service.clear(this.selected.map(x => x.id))
        )
      )
      .subscribe(() => {
        this.list.get();
        this.store.dispatch(new UpdateQueueSizeAction(this.data.totalCount - 1));
        this.cdr.detectChanges();
      });
  }

  onClear() {
    this.confirmation
      .warn('QueueService::DeleteConfirmationMessage', 'QueueService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.clear([]))
      )
      .subscribe(() => {
        this.list.get();
        this.store.dispatch(new UpdateQueueSizeAction(0));
      });
  }

  onSelect({ selected }: any): void {
    this.selected = [...selected];
  }

  inspectAll() {
    this.router.navigate(['/inspections/create']);
  }

  onBundleCerts() {
    if (this.selected?.length) {
      const ids = this.selected.filter(i => i.inspectionId).map(i => i.inspectionId);
      this.certService.bundleCertsFromIds(ids).subscribe(resp => {
        fileDownload(resp.data, resp.fileName, resp.contentType);
      });
    } else {
      // this.certService.bundleCerts(this.filters).subscribe(resp => {
      //   fileDownload(resp.data, resp.fileName, resp.contentType);
      // });
      const ids = this.data.items.filter(i => i.inspectionId).map(i => i.inspectionId);
      this.certService.bundleCertsFromIds(ids).subscribe(resp => {
        fileDownload(resp.data, resp.fileName, resp.contentType);
      });
    }
  }
}
