import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  LPX_TRANSLATE_TOKEN,
  LpxAvatarModule,
  LpxBrandLogoModule,
  LpxIconModule,
  LpxNavbarModule,
  LpxTranslateModule,
  ToObservableModule
} from '@volo/ngx-lepton-x.core';
import { LpxThemeModule } from '@volosoft/ngx-lepton-x';

import { AppMobileNavbarComponent } from './mobile-navbar.component';
import { MobileMenuTranslateDefaults } from './mobile-menu-translate.enum';

@NgModule({
  declarations: [AppMobileNavbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    LpxAvatarModule,
    LpxBrandLogoModule,
    LpxIconModule,
    LpxNavbarModule,
    LpxTranslateModule,
    ToObservableModule,
    LpxThemeModule
  ],
  exports: [AppMobileNavbarComponent],
})
export class AppLpxMobileNavbarModule  {
  static forRoot(): ModuleWithProviders<AppLpxMobileNavbarModule > {
    return {
      ngModule: AppLpxMobileNavbarModule ,
      providers: [
        {
          provide: LPX_TRANSLATE_TOKEN,
          useValue: [MobileMenuTranslateDefaults],
          multi: true,
        },
      ],
    };
  }
}
