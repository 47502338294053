// import the interface
import { Queue } from '../models/queue.model';
import { QueueAction, QueueActionType } from '../actions/queue.actions';
//create a dummy initial state
const initialState: Queue = {
    size: 0,
    failedIds: [],
};

export function QueueReducer(
    state: Queue = initialState,
    action: QueueAction
) {
    switch (action.type) {
        case QueueActionType.UPDATE_QUEUE_SIZE:
            return { ...state, size: action.payload };
        default:
            return state;
    }
}
