import { EnvironmentService, Rest, RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { serialize } from 'object-to-formdata';
import type {
  AssetCreateDto,
  AssetDto,
  AssetUpdateDto,
  AssetDetailsDto,
  GetAssetsInput,
  AssetStatusDto,
  AssetsUpdateDto,
} from './models';
import type { LookupDto, LookupRequestDto } from '@proxy/shared/models';
import {
  AttributeCreateDto,
  AttributeDto,
  AttributeUpdateDto,
  GetAttributesInput,
  GetMetersInput,
  MeterCreateDto,
  MeterDto,
  MeterUpdateDto,
} from '@proxy/register-service/assets';
import { AssetLookupRequestDto } from '../shared';
import { LocationAssetCount } from '../locations';
import { AttachmentDto, AttachmentUpdateDto } from '@proxy/attachments';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  apiName = 'RegisterService';
  urlPrefix = '/api/register/assets';

  create = (input: AssetCreateDto, ignorePrimaryIDDuplicate?: boolean) => {
    const formdata = serialize(input, { indices: true, dotsForObjectNotation: true });

    return this.restService.request<any, AssetDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        params: {
          ignorePrimaryIDDuplicate
        },
        body: formdata,
      },
      { apiName: this.apiName }
    );
  };

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, AssetDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  isAssetPrimaryIDAvailable = (primaryID: string, ignoreAssetId?: string) =>
    this.restService.request<any, { isAvailable: boolean }>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/primary-id-available`,
        params: {
          primaryID,
          ignoreAssetId
        },
      },
      { apiName: this.apiName }
    );

  getList = (input: GetAssetsInput, exactMatch?: boolean) =>
    this.restService.request<any, PagedResultDto<AssetDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          term: input.term,
          code: input.code,
          primaryID: input.primaryID,
          secondaryID: input.secondaryID,
          primaryOrSecondaryID: input.primaryOrSecondaryID,
          barcode: input.barcode,
          rfid: input.rfid,
          name: input.name,
          nickname: input.nickname,
          origCertNumber: input.origCertNumber,
          isFixed: input.isFixed,
          isActive: input.isActive,
          notes: input.notes,
          manufacturerId: input.manufacturerId,
          modelId: input.modelId,
          categoryId: input.categoryId,
          includeSubCategories: input.includeSubCategories,
          siteId: input.siteId,
          locationId: input.locationId,
          includeSubLocations: input.includeSubLocations,
          parentId: input.parentId,
          customerId: input.customerId,
          exactMatch: exactMatch,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getSimpleList = (input: GetAssetsInput, exactMatch?: boolean) =>
    this.restService.request<any, PagedResultDto<AssetDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/simple`,
        params: {
          term: input.term,
          code: input.code,
          primaryID: input.primaryID,
          secondaryID: input.secondaryID,
          primaryOrSecondaryID: input.primaryOrSecondaryID,
          barcode: input.barcode,
          rfid: input.rfid,
          name: input.name,
          nickname: input.nickname,
          origCertNumber: input.origCertNumber,
          isFixed: input.isFixed,
          isActive: input.isActive,
          notes: input.notes,
          manufacturerId: input.manufacturerId,
          modelId: input.modelId,
          categoryId: input.categoryId,
          includeSubCategories: input.includeSubCategories,
          siteId: input.siteId,
          locationId: input.locationId,
          includeSubLocations: input.includeSubLocations,
          parentId: input.parentId,
          customerId: input.customerId,
          exactMatch: exactMatch,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getLookup = (input: AssetLookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          siteId: input.siteId,
          modelId: input.modelId,
          locationId: input.locationId,
          categoryId: input.categoryId,
          manufacturerId: input.manufacturerId,
          parentId: input.parentId,
        },
      },
      { apiName: this.apiName }
    );

  getDetails = (id: string) =>
    this.restService.request<any, AssetDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/details`,
      },
      { apiName: this.apiName }
    );

  getTags = (id: string, input: LookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/tags`,
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getStatus = (id: string) =>
    this.restService.request<any, AssetStatusDto[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/status`,
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: AssetUpdateDto) => {
    const formData = serialize(input, { indices: true, dotsForObjectNotation: true });

    return this.restService.request<any, AssetDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: formData,
      },
      { apiName: this.apiName }
    );
  };

  changeState = (input: AssetsUpdateDto) => {
    return this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/activate`,
        body: input,
      },
      { apiName: this.apiName }
    );
  };

  getPicture = (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/picture`,
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  getPictureUrl = (id: string) => (id ? `${this.urlPrefix}/${id}/picture` : null);

  deletePicture = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/picture`,
      },
      { apiName: this.apiName }
    );

  addAttribute = (id: string, input: AttributeCreateDto) =>
    this.restService.request<any, AttributeDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/attributes`,
        body: input,
      },
      { apiName: this.apiName }
    );

  deleteAttribute = (id: string, name: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/attributes/${name}`,
      },
      { apiName: this.apiName }
    );

  getAttribute = (id: string, name: string) =>
    this.restService.request<any, AttributeDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/attributes/${name}`,
      },
      { apiName: this.apiName }
    );

  getAttributeList = (input: GetAttributesInput) =>
    this.restService.request<any, PagedResultDto<AttributeDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${input.assetId}/attributes`,
        params: {
          term: input.term,
          name: input.name,
          label: input.label,
          readingType: input.valueType,
          value: input.value,
          assetId: input.assetId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  updateAttribute = (id: string, name: string, input: AttributeUpdateDto) =>
    this.restService.request<any, AttributeDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/attributes/${name}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  addMeter = (id: string, input: MeterCreateDto) =>
    this.restService.request<any, MeterDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/meters`,
        body: input,
      },
      { apiName: this.apiName }
    );

  deleteMeter = (id: string, name: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/meters/${name}`,
      },
      { apiName: this.apiName }
    );

  getMeter = (id: string, name: string) =>
    this.restService.request<any, MeterDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/meters/${name}`,
      },
      { apiName: this.apiName }
    );

  getMeterList = (input: GetMetersInput) =>
    this.restService.request<any, PagedResultDto<MeterDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${input.assetId}/meters`,
        params: {
          term: input.term,
          name: input.name,
          label: input.label,
          readingType: input.readingType,
          reading: input.reading,
          startReading: input.startReading,
          assetId: input.assetId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  updateMeter = (id: string, name: string, input: MeterUpdateDto) =>
    this.restService.request<any, MeterDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/meters/${name}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getAssetsCountByLocation = (input: GetAssetsInput) =>
    this.restService.request<any, LocationAssetCount[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/assets-count-per-location`,
        params: {
          customerId: input.customerId,
          locationId: input.locationId,
          includeSubLocations: input.includeSubLocations
        },
      },
      { apiName: this.apiName }
    );

  removeAttachment = (id: string, attachmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `${this.urlPrefix}/${id}/attachments/${attachmentId}`,
    },
    { apiName: this.apiName,...config });

  updateAttachment = (id: string, attachmentId: string, input: AttachmentUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttachmentDto>({
      method: 'PUT',
      url: `${this.urlPrefix}/${id}/attachments/${attachmentId}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService, private environment: EnvironmentService) {}
}
