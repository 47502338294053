import { Action } from '@ngrx/store';
import { Queue } from '../models/queue.model';

export enum QueueActionType {
    UPDATE_QUEUE_SIZE = '[n] Update queue size',
}

export class UpdateQueueSizeAction implements Action {
    readonly type = QueueActionType.UPDATE_QUEUE_SIZE;
    constructor(public payload: number) {}
}

export type QueueAction = UpdateQueueSizeAction;
